import { useState } from 'react'
import router from 'next/router'

import Modal from '../../../layouts/Modal'
import { ModalName } from '../../ModalManager'
import IndividualCharInputBox from '../../IndividualCharInputBox/IndividualCharInputBox'
import ModalHeader from '../../ModalHeader'
import { BottomSheetWrapper } from '../../styleComponents/BottomSheetWrapper'

import { useSelector } from 'react-redux'
import { RootState, useThunkyDispatch } from '../../../redux/store'
import AppAction from '../../../redux/actions/AppAction'
import Scanner from '../../Scanner'
import AuditAction from '../../../redux/actions/AuditAction'
import Quagga from 'quagga'
import { AuditItem } from '../../../types/AuditItem'

const ExtraItemModal = ({
	complete,
	id,
	storageid,
	action,
	storagelabel,
	productModal = false,
	auditType,
	productid,
	itemWiseStorageModal = false,
}) => {
	const deviceType = useSelector(
		(state: RootState) => state.appState.deviceType
	)

	const [showManualInputBox, setshowManualInputBox] = useState(
		deviceType == 'mobile' ? false : true
	)
	// const [showProductModal , setShowProductModal] = useState(false);
	const [newStorageID, setNewStorageID] = useState(null)
	const rootState = useSelector((state: RootState) => state)
	const dispatch = useThunkyDispatch()
	const distictAduitProducts: AuditItem[] =
		rootState.appState.distictAduitProducts

	const onDetect = async (barcode: { barcode: string }) => {
		if (!productModal) {
			verifyStorage(barcode)
			// Quagga.start();
		} else {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const data: any = await getProductData(barcode)
			if (data != null && data.length !== 0) {
				action(data)

				router.push(
					`/audit/${id}/items/reportextraitem?itemid=${data.id}&storageid=${storageid}`
				)
				try {
					Quagga.stop()
				} catch (error) {
					console.log(error)
				}
				complete()
			} else {
				dispatch(AppAction.displayMessage('Product not found', true, 2000))
			}
		}
	}
	const manualInput = () => {
		setshowManualInputBox(true)
	}
	const verifyStorage = async (data) => {
		if (storagelabel === data) {
			await complete()
			dispatch(
				AppAction.switchModal(ModalName.EXTRA_ITEM_MODAL, {
					action: action,
					id: id,
					storageid: storageid,
					storagelabel: storagelabel,
					productModal: true,
					message: 'Extra Items',
				})
			)
			// setshowManualInputBox(false);

			dispatch(
				AppAction.displayMessage(
					'Location verified , please scan the product  ',
					false,
					2000
				)
			)
		} else {
			dispatch(AppAction.displayMessage('Incorrect storage ', true, 2000))
		}
		// return storageid === data;
	}
	const handleBarCodeDigitsInput = async (barcode: string) => {
		if (barcode != '' && barcode.length == 5) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const data: any = await getProductData(barcode)
			if (data != null && data.length !== 0) {
				action(data)

				router.push(
					`/audit/${id}/items/reportextraitem?itemid=${data.id}&storageid=${storageid}`
				)

				try {
					Quagga.stop()
				} catch (error) {
					console.log(error)
				}
				complete()
			} else {
				dispatch(AppAction.displayMessage('Product not found', true, 2000))
			}
			// router.push(`audit/${id}/items/+${data.id}}?extraItem=true`);
		}
	}

	const handleStorageChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const qt = event.target.value
		if (qt != null && qt != '') {
			if (qt === storagelabel) {
				await complete()
				dispatch(
					AppAction.switchModal(ModalName.EXTRA_ITEM_MODAL, {
						action: action,
						id: id,
						storageid: storageid,
						storagelabel: storagelabel,
						productModal: true,
						message: 'Extra Items',
					})
				)
				setshowManualInputBox(false)

				dispatch(
					AppAction.displayMessage(
						'Location verified , please scan the product  ',
						false,
						2000
					)
				)
			}
		}
	}

	const getProductData = async (barcode) => {
		const data = await dispatch(AuditAction.searchProduct(barcode))
		return data
	}

	const selectProduct = async (value) => {
		if (value) {
			await complete()
			dispatch(
				AppAction.switchModal(ModalName.EXTRA_ITEM_MODAL, {
					action: action,
					id: id,
					storageid: storageid,
					storagelabel: storagelabel,
					productid: value,
					itemWiseStorageModal: true,
					message: 'Extra Items',
					auditType: 'product',
				})
			)
			// setshowManualInputBox(false);

			dispatch(
				AppAction.displayMessage(
					'Product selected , please scan the storage  ',
					false,
					2000
				)
			)
		} else {
			alert('Please Select a Product')
		}
	}

	const selectStorage = () => {
		if (newStorageID) {
			router.push(
				`/audit/${id}/items/reportextraitem?itemid=${productid}&storageid=${newStorageID}`
			)
			complete()
		} else {
			alert('invalid storage selected')
		}
	}

	return (
		<Modal fullScreen={true}>
			{auditType != 'product' && !showManualInputBox && (
				<Scanner onDetect={onDetect} />
			)}
			{auditType != 'product' && (
				<ModalHeader title={productModal ? 'Scan Product' : 'Scan Storage'} />
			)}

			{auditType != 'product' && productModal && (
				<BottomSheetWrapper>
					<i
						onClick={() => setshowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input numbers
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>
								Enter the last 5 digit of the barcode
							</div>
							<IndividualCharInputBox
								amount={5}
								autoFocus
								handleOutputString={handleBarCodeDigitsInput}
								inputRegExp={/^[0-9]$/}
								type='number'
							/>
						</>
					)}
				</BottomSheetWrapper>
			)}

			{auditType != 'product' && !productModal && (
				<BottomSheetWrapper>
					<i
						onClick={() => setshowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input Storage Label
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>Enter the Storage Label</div>

							<input
								autoFocus
								id='name'
								type='text'
								placeholder={storageid}
								onChange={handleStorageChange}
							/>
						</>
					)}
				</BottomSheetWrapper>
			)}

			{/* audit type == product */}
			{auditType == 'product' &&
				itemWiseStorageModal &&
				!showManualInputBox && <Scanner onDetect={onDetect} />}
			{auditType == 'product' && (
				<ModalHeader
					title={itemWiseStorageModal ? 'Scan Storage' : 'Select Product'}
				/>
			)}
			{auditType == 'product' && !itemWiseStorageModal && (
				<select
					onChange={(e) => selectProduct(e.target.value)}
					style={{ marginTop: 100, width: '100%' }}
				>
					<option value=''>Select a product</option>
					{distictAduitProducts &&
						distictAduitProducts?.map((item, index) => {
							return (
								<option key={index} value={item.product.id}>
									{item.product.name}
								</option>
							)
						})}
				</select>
			)}

			{itemWiseStorageModal && auditType == 'product' && (
				<BottomSheetWrapper>
					<i
						onClick={() => setshowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input Storage Label
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>Enter the Storage Label</div>

							<input
								autoFocus
								id='name'
								type='text'
								onChange={(event) => {
									setNewStorageID(event.target.value)
								}}
							/>
							<button
								className='btn btn-sm btn-green mt-lg w-full'
								onClick={selectStorage}
							>
								Assign Storage
							</button>
						</>
					)}
				</BottomSheetWrapper>
			)}
		</Modal>
	)
}

export default ExtraItemModal
