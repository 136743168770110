import { useState } from 'react'
import Modal from '../../../layouts/Modal'
import { ModalProps } from '../../ModalManager'
import {
	PrimaryButton,
	PrimaryOutlineButton,
} from '../../NewDesignComponents/NewButtons'

interface Props extends ModalProps {
	action: (id: string | number) => void
	message: string
	content?: string
	id: number | string
	confirmButtonText?: string
	discardButtonText?: string
	inputType: string
	inputPlaceholder: string
}

const NewConfirmModalWithInput = ({
	complete,
	action,
	message,
	content,
	// id,
	confirmButtonText = 'Yes',
	discardButtonText = 'No',
	inputType,
	inputPlaceholder,
}: Props) => {
	const discard = () => {
		complete()
	}

	const success = () => {
		action(inputValue)
		complete()
	}

	const [inputValue, setInputValue] = useState('')

	return (
		<Modal smallModal={true}>
			<h1 className='title center'>{message}</h1>
			{content && (
				<div className='md mt-md black margin-bottom-10'>{content}</div>
			)}
			<input
				className='input input-md'
				type={inputType}
				placeholder={inputPlaceholder}
				value={inputValue}
				onChange={(e) => {
					setInputValue(e.target.value)
				}}
				min={0}
			/>
			<div className='buttonWrapper mt-md'>
				<PrimaryOutlineButton
					text={discardButtonText}
					handleClick={discard}
					id='none'
				/>
				<PrimaryButton
					text={confirmButtonText}
					handleClick={success}
					id='none'
					disabled={inputValue == ''}
				/>
			</div>
		</Modal>
	)
}

export default NewConfirmModalWithInput
