import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import Modal from '../../../layouts/Modal'
import AuditNewAction from '../../../redux/actions/AuditNewAction'
import ModalHeader from '../../ModalHeader'
import Image from '../../newAudit/Image'
import VisualText from '../../NewDesignComponents/VisualText'
import styles from '../../NewDesignComponents/auditProductsCard.module.css'
import { ProductData } from '../../../types/AuditNewTypes'

interface Props {
	complete: () => void
	productBarcode: string
	action: (barcode?: string | number) => void
	storageLabel: string
}

const NoOptionsMessage = () => {
	return (
		<div className='margin-x-16 margin-y-8'>
			<p>No Products found</p>
		</div>
	)
}

const Product = (props) => {
	const { data, innerProps } = props

	return (
		<div {...innerProps} className='p-sm'>
			<div className={styles.auditProductCardWrapper} style={{ padding: '0' }}>
				<div className={styles.infoWrapper}>
					<div className={styles.productImageWrapper}>
						<Image src={data.imgage_url} alt={data.name || 'product'} />
					</div>
					<div className={styles.info}>
						<div className={styles.infoTop}>
							<VisualText size={'Tertiary'} color={'Secondary'}>
								PID - {data.id}
							</VisualText>
							<VisualText size={'Tertiary'} color={'Secondary'}>
								MRP - ₹{data.mrp}
							</VisualText>
						</div>
						<VisualText size={'Secondary'} color={'Primary'}>
							{data.name}
						</VisualText>
					</div>
				</div>
			</div>
		</div>
	)
}

const NewAuditProductSearchModal = ({
	complete,
	action,
	productBarcode,
}: Props) => {
	const [availableData, setAvailableData] = useState<ProductData[] | []>([])
	const [loading, setLoading] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState<number>(0)
	const dispatch = useDispatch()

	const loadProductFromBarcode = async (productBarcode: string) => {
		setLoading(true)
		const response = await dispatch(
			AuditNewAction.getProductsFromBarcode({
				product_barcode: productBarcode,
			})
		)
		setLoading(false)
		if (response) {
			setAvailableData(response as unknown as ProductData[])
		}
	}

	useEffect(() => {
		loadProductFromBarcode(productBarcode)
	}, [])

	const handleNewProductSubmit = () => {
		complete()
		action(selectedProduct)
	}

	return (
		<Modal fullScreen={true}>
			<ModalHeader title={'Add New Product'} />
			<form className='margin-x-16' style={{ marginTop: '6rem' }}>
				<div className='flex-between margin-bottom-10'>
					<h2 className='bold'>Product Barcode</h2>
					<h2 className='bold'>{productBarcode}</h2>
				</div>
				<label className='label'>Select from Available Products</label>
				<div className='grid  c-center'>
					<div className='col-12 margin-y-16'>
						<Select
							className='basic-single'
							key={productBarcode}
							placeholder={'Select Product'}
							isLoading={loading}
							isSearchable={false}
							name='Product ID'
							defaultMenuIsOpen={true}
							getOptionLabel={(e: ProductData) => `${e.name} | ${e.id}`}
							options={availableData}
							onChange={(e: ProductData) => {
								setSelectedProduct(e.id)
							}}
							components={{
								Option: Product,
								NoOptionsMessage: NoOptionsMessage,
							}}
						/>
					</div>
				</div>
				<div className='margin-top-20 center'>
					<button
						disabled={selectedProduct == null || selectedProduct == 0}
						onClick={handleNewProductSubmit}
						className='btn btn-blue'
					>
						Submit
					</button>
				</div>
			</form>
		</Modal>
	)
}

export default NewAuditProductSearchModal
