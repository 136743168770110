import Modal from '../../../layouts/Modal'
import React, { FormEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useThunkyDispatch } from '../../../redux/store'
import Toolkit from '../../../utils/Toolkit'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import Scanner from '../../Scanner'
import { TransferItemSchema } from '../../../types/TransferItem'
import TransferAPI from '../../../apis/TransferAPI'
import { AppActionTypes } from '../../../redux/ActionTypes'

const TransferModal = ({ item, addToCart, complete, remarks }) => {
	const [flag, setFlag] = useState<boolean>(false)
	const [selectedStorage, setSelectedStorage] = useState('')
	const [selectedProduct, setSelectedProduct] = useState<string>(null)
	const [quantity, setQuantity] = useState<number>(
		item.quantity - item.placed_quantity
	)
	const [reason, setReason] = useState<string>('')
	const [scannerType, setScannerType] = useState(null)
	const [productBarcode, setProductBarcode] = useState(undefined)
	const [storageBarcode, setStorageBarcode] = useState(undefined)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [errors, setErrors] = useState<any>({})

	const dispatch = useThunkyDispatch()

	const submit = async (e: FormEvent) => {
		e.preventDefault()

		if (quantity && quantity > 0) {
			const apiParams = {
				product: selectedProduct,
				source_location: selectedStorage,
				quantity: quantity,
				remarks: reason,
				migration_item_id: item.id,
				sku: item.sku,
			}

			const errors = Toolkit.validate(TransferItemSchema, apiParams)
			setErrors(errors)
			if (Object.keys(errors).length == 0) {
				addToCart(apiParams)
				setFlag(!flag)
				setTimeout(() => {
					complete()
				}, 200)
			}

			return
		}

		dispatch({
			type: AppActionTypes.DISPLAY_MESSAGE,
			payload: {
				text: 'Enter a valid Quanity',
				isError: true,
				milliseconds: 2000,
			},
		})
	}

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span>No Options Found</span>
			</components.NoOptionsMessage>
		)
	}

	const rootState = useSelector((state: RootState) => state)
	const transferlist = rootState.appState.transferList
	const apiProps = Toolkit.mapStateToApiProps(rootState)

	const loadProducts = async (value) => {
		const val = value || productBarcode
		const response = await new TransferAPI(apiProps).searchProduct(val)
		const filteredProductlist = response.data.data
		return filteredProductlist
		//const response = await new PutawayApi(apiProps).searchProduct(val);
		//return response.data.data;
	}

	const loadStorages = async (value) => {
		const val = value || storageBarcode

		const filteredProductlist = transferlist.filter(
			(item) => item.source_storage.label.toString().indexOf(val) > -1
		)

		return filteredProductlist
		//const response = await new PutawayApi(apiProps).searchStorage(val);
		//return response.data.data;
	}

	const onDetect = (barcode: { barcode: string }) => {
		if (scannerType == 'product') {
			setProductBarcode(barcode)
		} else {
			setStorageBarcode(barcode)
		}
		setScannerType(null)
	}

	const checkIfProductMatches = () => {
		if (item.product.id === selectedProduct) {
			return true
		} else {
			return false
		}
	}

	const checkIfStorageMatches = () => {
		if (selectedStorage === item.source_storage.id) {
			return true
		} else {
			return false
		}
	}

	return (
		<Modal>
			<h1 className='title mt-lg'>SOURCE LOCATION</h1>
			<div>
				<h5>Product Name : {item.product.name}</h5>
				<h5>Source Location : {item.source_storage.label}</h5>
				<h5>Quantity Assigned: {item.quantity - item.placed_quantity}</h5>
			</div>

			<form className='mt-lg' onSubmit={submit}>
				<div>
					<label className='label'>Scan Location</label>
					<div className='grid gutter-md c-center'>
						<div className='col-10'>
							<AsyncSelect
								autoFocus
								key={storageBarcode}
								components={{ NoOptionsMessage }}
								getOptionLabel={(e) => e.source_storage.label}
								getOptionValue={(e) => e.source_storage.id}
								loadOptions={loadStorages}
								onChange={(value) =>
									setSelectedStorage(value.source_storage.id)
								}
								defaultInputValue={storageBarcode}
								defaultOptions={!!storageBarcode}
								defaultMenuIsOpen={!!storageBarcode}
							/>
						</div>
						<div className='col-2 center'>
							{!checkIfStorageMatches() ? (
								<button
									style={scannerBtnStyle}
									className='btn btn-orange'
									type='button'
									onClick={() => setScannerType('storage')}
								>
									<span className='fas fa-map-marker-alt' />
								</button>
							) : (
								<i className='fas fa-check green'></i>
							)}
						</div>
					</div>
					<span className='error'>
						{errors.source_location ? errors.source_location[0] : null}
					</span>
				</div>

				<div className='mt-md'>
					<label className='label'>Scan Product</label>
					<div className='grid gutter-md c-center'>
						<div className='col-10'>
							<AsyncSelect
								autoFocus
								key={productBarcode}
								components={{ NoOptionsMessage }}
								getOptionLabel={(e) => `${e.name}: ${e.barcode}`}
								getOptionValue={(e) => e.id}
								loadOptions={loadProducts}
								onChange={(value: {
									id: string
									name: string
									barcode: string
								}) => {
									setSelectedProduct(value.id)
								}}
								defaultInputValue={productBarcode}
								defaultOptions={!!productBarcode}
								defaultMenuIsOpen={!!productBarcode}
							/>
						</div>
						<div className='col-2 center'>
							{!checkIfProductMatches() ? (
								<button
									style={scannerBtnStyle}
									className='btn btn-orange'
									type='button'
									onClick={() => setScannerType('product')}
								>
									<span className='fas fa-qrcode' />
								</button>
							) : (
								<i className='fas fa-check green'></i>
							)}
						</div>
						<span className='error'>
							{errors.product ? errors.product[0] : null}
						</span>
					</div>
				</div>

				<div>
					<label className='label'>Quantity</label>
					<input
						value={quantity}
						max={item.quantity - item.placed_quantity}
						min={0}
						onChange={(e) => {
							setQuantity(
								isNaN(parseInt(e.target.value))
									? null
									: parseInt(e.target.value)
							)
							if (item.quantity > e.target.value) {
								setReason(remarks[0])
							} else {
								setReason('')
							}
						}}
						className='input input-sm'
						type='number'
					/>
				</div>
				<span className='error'>
					{errors.quantity ? errors.quantity[0] : null}
				</span>

				{quantity && item.quantity - item.placed_quantity > quantity && (
					<>
						<div className='mt-md'>
							<label className='label'>
								Select the reason for picking less quantity.
							</label>
							<select
								value={reason}
								className='input input-sm'
								onChange={(e) => setReason(e.target.value)}
							>
								{remarks.map((value, key) => (
									<option key={key} value={value}>
										{value}
									</option>
								))}
							</select>
						</div>
						<span className='error'>
							{errors.reason ? errors.reason[0] : null}
						</span>
					</>
				)}

				<div className='mt-md'>
					{flag === false ? (
						<>
							<button
								disabled={!checkIfProductMatches() || !checkIfStorageMatches()}
								className='btn btn-orange w-full flex'
							>
								<div className='col-11'>ADD TO CART</div>
								<div className='col-1'>
									<span className='fas fa-cart-plus' />
								</div>
							</button>
						</>
					) : (
						<button
							className='btn btn-green w-full flex'
							disabled
							style={{
								background: '#1A9C80',
							}}
						>
							<div className='col-11'>ADDED TO CART </div>

							<div className='col-1'>
								<span className='fas fa-check' />
							</div>
						</button>
					)}
				</div>
			</form>
			{scannerType && (
				<>
					<div className={`full modal`} style={{ zIndex: 100 }}>
						<div className='close' onClick={() => setScannerType(null)}>
							<i className='fas fa-times'></i>
						</div>
						<Scanner onDetect={onDetect} />
					</div>
				</>
			)}
		</Modal>
	)
}

const scannerBtnStyle = {
	display: 'flex',
	justifyContent: 'center',
	height: '2.5em',
	width: '2.5em',
}

export default TransferModal
