import { MouseEventHandler } from 'react'
import styled from 'styled-components'

interface Props {
	label: string
	handleClick: MouseEventHandler<HTMLButtonElement>
	isDisabled: boolean
	primary?: boolean
	secondary?: boolean
	borderRadius?: string
}

const Button = styled.button<{ borderRadius: string }>`
    border-radius: ${(props: {borderRadius? : string;}) => props.borderRadius ? props.borderRadius : "4px"}
`;

const getButtonClassName = (primary, secondary): string => {
	if (secondary) return 'btn w-full h-full btn-grey-new'

	//for primary and default
	return 'btn w-full h-full btn-orange-pop'
}

const CustomPrimaryButton = (props: Props) => {
	const { label, handleClick, isDisabled, primary, secondary, borderRadius } =
		props

	return (
		<Button
			className={getButtonClassName(primary, secondary)}
			disabled={isDisabled}
			onClick={handleClick}
			borderRadius={borderRadius}
		>
			{label}
		</Button>
	)
}

export default CustomPrimaryButton
