import { ReactChild, ReactChildren } from 'react'
import styled, { css } from 'styled-components'

interface TextProps {
	children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
	size: 'Primary' | 'Secondary' | 'Tertiary'
	weight?: 'Primary' | 'Secondary'
	color: 'Primary' | 'Secondary' | 'Tertiary'
	[key: string]: unknown
}

const VisualText = ({
	size,
	color,
	children,
	weight = 'Secondary',
	...restProps
}: TextProps) => {
	return (
		<>
			<StyledText size={size} color={color} weight={weight} {...restProps}>
				{children}
			</StyledText>
		</>
	)
}

export default VisualText

export const StyledText = styled.div<{
	size: 'Primary' | 'Secondary' | 'Tertiary'
	color: 'Primary' | 'Secondary' | 'Tertiary'
	weight: 'Primary' | 'Secondary'
}>`
	${(props) => {
		if (props.color === 'Primary') {
			return css`
				color: var(--primary-text-color);
			`
		} else if (props.color === 'Secondary') {
			return css`
				color: var(--secondary-text-color);
			`
		} else if (props.color === 'Tertiary') {
			return css`
				color: var(--tertiary-text-color);
			`
		}
		return css`
			color: var(--primary-text-color);
		`
	}}

	${(props) => {
		if (props.size === 'Primary') {
			return css`
				font-size: var(--primary-text-font-size);
				/* font-weight: var(--primary-text-font-weight); */
				line-height: var(--primary-text-line-height);
			`
		} else if (props.size === 'Secondary') {
			return css`
				font-size: var(--secondary-text-font-size);
				/* font-weight: var(--primary-text-font-weight); */
				line-height: var(--secondary-text-line-height);
			`
		} else if (props.size === 'Tertiary') {
			return css`
				font-size: var(--tertiary-text-font-size);
				font-weight: var(--tertiary-text-font-weight);
				line-height: var(--tertiary-text-line-height);
			`
		}
		return css`
			font-size: var(--primary-text-font-size);
			/* font-weight: var(--primary-text-font-weight); */
			line-height: var(--primary-text-line-height);
		`
	}}

    ${(props) => {
		if (props.weight === 'Primary') {
			return css`
				font-weight: var(--primary-text-font-weight);
			`
		} else if (props.weight === 'Secondary') {
			return css`
				font-weight: var(--secondary-text-font-weight);
			`
		}
		return css`
			font-weight: var(--primary-text-font-weight);
		`
	}}
`
