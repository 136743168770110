import Modal from '../../layouts/Modal'
import { ModalProps } from '../ModalManager'
import Scanner from '../Scanner'

interface Props extends ModalProps {
	setBarcode: (barcode: { barcode: string }) => void
}

const ScanModal = ({ complete, setBarcode }: Props) => {
	const onDetect = (barcode: { barcode: string }) => {
		setBarcode(barcode)
		complete()
	}

	return (
		<Modal fullScreen={true}>
			<Scanner onDetect={onDetect} />
		</Modal>
	)
}

export default ScanModal
