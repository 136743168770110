import { ThunkyAction } from '../store'
import Toolkit from '../../utils/Toolkit'
import { AppActionTypes } from '../ActionTypes'
import AuditNewApi from '../../apis/AuditNewApi'
import { getErrorText } from '../../utils/helperFunctions'

interface submitBadQuantityAudit {
	audit_item_id: string | string[]
	audit_quantity: string | number
	bad_quantity: {
		quantity: string | number
		reason: string
	}[]
	remarks: string
	storage_label: string | string[]
}

interface submitShelfLifeAudit {
	audit_item_id: string | number
	expiry_dates: {
		expiry_date: string
		quantity: string | number
	}[]
	storageLabel: string
}

const loadAuditList = ({
	page,
	status,
}: {
	page: number
	status?: string | number | null
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ThunkyAction<Promise<any>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).loadAuditList({
				page,
				status,
			})
			return response?.data
		} catch (e) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: { text: getErrorText(e), isError: true, milliseconds: 2000 },
			})
		}
	}
}

const loadAuditItems = ({
	page,
	auditId,
}: {
	page: number
	auditId?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ThunkyAction<Promise<any>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).loadAuditItems({
				page,
				auditId,
			})
			return response?.data
		} catch (e) {
			// dispatch({
			// 	type: AppActionTypes.DISPLAY_MESSAGE,
			// 	payload: { text: getErrorText(e), isError: true, milliseconds: 2000 },
			// })
		}
	}
}

const loadProductsInALocation = ({
	page,
	auditId,
	locationId,
}: {
	page: number
	auditId?: string
	locationId?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ThunkyAction<Promise<any>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).loadProductsInALocation({
				page,
				auditId,
				locationId,
			})
			return response?.data
		} catch (e) {
			// dispatch({
			// 	type: AppActionTypes.DISPLAY_MESSAGE,
			// 	payload: { text: getErrorText(e), isError: true, milliseconds: 2000 },
			// })
			console.log(e)
		}
	}
}

const productNotFound = ({
	audit_item_id,
}: {
	audit_item_id: number | string
}): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).productNotFound({
				audit_item_id,
			})
			return response?.data
		} catch (error) {
			console.log('error', error)
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}

const productStatusChange = ({
	audit_item_id,
}: {
	audit_item_id: string | number
}): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).productStatusChange({
				audit_item_id,
			})
			return response?.data
		} catch (error) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}

const submitBadQuantityAudit = ({
	audit_item_id,
	audit_quantity,
	bad_quantity,
	remarks,
	storage_label,
}: submitBadQuantityAudit): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).submitBadQuantityAudit({
				audit_item_id,
				audit_quantity,
				bad_quantity,
				remarks,
				storage_label,
			})
			return response?.data
		} catch (error) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}
const submitShelfLifeAudit = ({
	audit_item_id,
	expiry_dates,
	storageLabel,
}: submitShelfLifeAudit): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).submitShelfLifeAudit({
				audit_item_id,
				expiry_dates,
				storageLabel,
			})
			return response?.data
		} catch (error) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}

const submitDifferenceInQuantity = ({
	audit_item_id,
	quantity,
}: {
	audit_item_id: string | number
	quantity: string | number
}): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(
				apiProps
			).submitDifferenceInQuantity({
				audit_item_id,
				quantity,
			})
			return response?.data
		} catch (error) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}

const getAuditItemData = ({
	audit_item_id,
}: {
	audit_item_id: string | string[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ThunkyAction<Promise<any>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).getAuditItemData({
				audit_item_id,
			})
			return response?.data
		} catch (error) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}

const addExtraInventory = ({
	audit_id,
	product_barcode,
	storage_label,
	product_id,
}: {
	audit_id: string
	product_barcode: string
	storage_label: string
	product_id: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ThunkyAction<Promise<any>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).addExtraInventory({
				audit_id,
				product_barcode,
				storage_label,
				product_id,
			})
			return response?.data
		} catch (error) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}

const getProductsFromBarcode = ({
	product_barcode,
}: {
	product_barcode: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ThunkyAction<Promise<any>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AuditNewApi(apiProps).getProductsFromBarcode({
				product_barcode,
			})
			return response?.data?.data?.results
		} catch (error) {
			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: {
					text: getErrorText(error),
					isError: true,
					milliseconds: 2000,
				},
			})
		}
	}
}

const AuditNewAction = {
	loadAuditList,
	loadAuditItems,
	loadProductsInALocation,
	productNotFound,
	productStatusChange,
	submitBadQuantityAudit,
	getAuditItemData,
	submitShelfLifeAudit,
	submitDifferenceInQuantity,
	addExtraInventory,
	getProductsFromBarcode,
}

export default AuditNewAction
