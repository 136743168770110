import Api from './Api'
export default class AuditApi extends Api {
	//TODO change stubs

	/**loadAudits = async() => {
        const url = this.getUrl("audits");
        //const items =  transferlist.filter(item=> item.id == id);
        return  items[0];
        //return await this.getClient().get(url);
    }*/

	loadGroupItems = async (id) => {
		const url = this.getUrl('audits/' + id)
		return await this.getClient().get(url)
	}

	loadItems = async (id, type, value) => {
		let url = ''
		if (type == null) {
			url = this.getUrl('audits/' + id + '/items')
		} else {
			url = this.getUrl('audits/' + id + '/items?' + type + '=' + value)
		}
		return await this.getClient().get(url)
	}

	loadProduct = async (auditid, pid) => {
		const url = this.getUrl('audits/' + auditid + '/items/' + pid)
		return await this.getClient().get(url)
	}

	inputAuditItem = async (auditid, pid, apiParams) => {
		const url = this.getUrl('audits/' + auditid + '/items/' + pid) + '/input'
		// c//onst items =  transferlist.filter(item=> item.id == id);
		//return  items[0];

		return await this.getClient().post(url, apiParams)
	}
	submitAuditItem = async (auditid, pid) => {
		const url = this.getUrl('audits/' + auditid + '/items/' + pid + '/submit')
		// c//onst items =  transferlist.filter(item=> item.id == id);
		//return  items[0];
		return await this.getClient().post(url)
	}

	searchProduct = async (barcode: number) => {
		const url = this.getUrl(`audits/search-products?barcode=${barcode}`)
		return await this.getClient().get(url)
	}

	getProduct = async (pid: number) => {
		const url = this.getUrl(`audits/get-product?productId=${pid}`)
		return await this.getClient().get(url)
	}

	getReasons = async () => {
		const url = this.getUrl(
			'search/reasons?reasonSource=audit&reasonType=badInventory'
		)
		//return reasons;

		return await this.getClient().get(url)
	}

	addExtraEnvontory = async (auditid, apiParams) => {
		const url = this.getUrl('audits/' + auditid + '/extra-inventory')
		// c//onst items =  transferlist.filter(item=> item.id == id);
		//return  items[0];
		return await this.getClient().post(url, apiParams)
	}
}
