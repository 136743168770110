import { NodeApi } from './Api'

interface submitBadQuantityAuditParams {
	audit_item_id: string | string[]
	audit_quantity: string | number
	bad_quantity: {
		quantity: string | number
		reason: string
	}[]
	remarks: string
	storage_label: string | string[]
}

interface addExtraInventory {
	audit_id: string
	product_barcode: string
	storage_label: string
	product_id: string
}

export default class AuditNewApi extends NodeApi {
	loadAuditList = async ({
		page,
		status,
	}: {
		page: number
		status?: string | number | null
	}) => {
		let url: string
		if (status) {
			url = this.getUrl(`audits/audits?page=${page}&status=${status}`)
		} else {
			url = this.getUrl(`audits/audits?page=${page}`)
		}
		return await this.getClient().get(url)
	}

	loadAuditItems = async ({
		page,
		auditId,
	}: {
		page: number
		auditId: string
	}) => {
		const url = this.getUrl(
			`audits/audit-view?page=${page}&audit_id=${auditId}`
		)
		return await this.getClient().get(url)
	}

	loadProductsInALocation = async ({
		page,
		auditId,
		locationId,
	}: {
		page: number
		auditId: string
		locationId: string
	}) => {
		let url = ''
		if (locationId != 'null') {
			url = this.getUrl(
				`audits/audit-product-list?page=${page}&audit_id=${auditId}&storage_id=${locationId}`
			)
		} else {
			url = this.getUrl(
				`audits/audit-product-list?page=${page}&audit_id=${auditId}`
			)
		}

		return await this.getClient().get(url)
	}

	productNotFound = async ({
		audit_item_id,
	}: {
		audit_item_id: number | string
	}) => {
		const url = this.getUrl(`audits/audit-input`)
		return await this.getClient().post(url, {
			audit_item_id: audit_item_id,
			audit_quantity: 0,
		})
	}

	productStatusChange = async ({
		audit_item_id,
	}: {
		audit_item_id: number | string
	}) => {
		const url = this.getUrl(`audits/audit-start-at-location`)
		return await this.getClient().post(url, { audit_item_id: audit_item_id })
	}

	submitBadQuantityAudit = async ({
		audit_item_id,
		audit_quantity,
		bad_quantity,
		remarks,
		storage_label,
	}: submitBadQuantityAuditParams) => {
		const url = this.getUrl(`audits/audit-input`)
		return await this.getClient().post(url, {
			audit_item_id: +audit_item_id,
			audit_quantity: +audit_quantity,
			bad_quantity,
			remarks,
			storage_label,
		})
	}

	submitShelfLifeAudit = async ({
		audit_item_id,
		expiry_dates,
		storageLabel,
	}: {
		audit_item_id: string | number
		expiry_dates: { expiry_date: string; quantity: string | number }[]
		storageLabel: string
	}) => {
		const url = this.getUrl(`audits/audit-input`)
		return await this.getClient().post(url, {
			audit_item_id: +audit_item_id,
			expiry_dates,
			storageLabel,
		})
	}

	submitDifferenceInQuantity = async ({
		audit_item_id,
		quantity,
	}: {
		audit_item_id: string | number
		quantity: string | number
	}) => {
		const url = this.getUrl(`audits/audit-report-difference-in-quantity`)
		return await this.getClient().post(url, {
			audit_item_id: +audit_item_id,
			quantity: +quantity,
		})
	}

	getAuditItemData = async ({
		audit_item_id,
	}: {
		audit_item_id: string | string[]
	}) => {
		const url = this.getUrl(
			`audits/audit-item-view?audit_item_id=${audit_item_id}`
		)
		return await this.getClient().get(url)
	}

	addExtraInventory = async ({
		audit_id,
		product_barcode,
		storage_label,
		product_id,
	}: addExtraInventory) => {
		const url = this.getUrl(`audits/audit-add-extra-inventory`)
		return await this.getClient().post(url, {
			audit_id: +audit_id,
			product_barcode: product_barcode,
			storage_label,
			product_id: +product_id,
		})
	}

	getProductsFromBarcode = async ({
		product_barcode,
	}: {
		product_barcode: string
	}) => {
		// sending status=1, to only get active products
		const url = this.getUrlWithoutApp(
			`products/products-by-barcode?product_barcode=${+product_barcode}&status=1`
		)
		return await this.getClient().get(url)
	}
}
