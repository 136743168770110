import Modal from '../../../layouts/Modal'
import React, { FormEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import Toolkit from '../../../utils/Toolkit'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import Scanner from '../../Scanner'
import TransferAPI from '../../../apis/TransferAPI'

const MigrationModal = ({ item, complete, putawayItem }) => {
	const [flag, setFlag] = useState<boolean>(false)
	const [selectedStorage, setSelectedStorage] = useState(null)
	const [selectedProduct, setSelectedProduct] = useState<string>(null)
	const [excessPutaway, setExcessPutaway] = useState<boolean>(false)
	const [quantity, setQuantity] = useState<number>(0)
	const [scannerType, setScannerType] = useState(null)
	const [productBarcode, setProductBarcode] = useState(undefined)
	const [storageBarcode, setStorageBarcode] = useState(undefined)

	const submit = async (e: FormEvent) => {
		e.preventDefault()
		const apiParams = {
			destination_storage_id: selectedStorage,
			//product: selectedProduct,
			putaway_item_id: item.id,
			placed_quantity: item.quantity - quantity,
			no_space: excessPutaway,
			//excess_quantity: quantity
		}
		setFlag(!flag)
		setTimeout(() => {
			putawayItem(apiParams)
			complete()
		}, 200)
	}

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span>No Items Found</span>
			</components.NoOptionsMessage>
		)
	}

	const rootState = useSelector((state: RootState) => state)
	//const transferlist = rootState.appState.transferList;
	const apiProps = Toolkit.mapStateToApiProps(rootState)

	const loadProducts = async (value) => {
		const val = value || productBarcode
		const response = await new TransferAPI(apiProps).searchProduct(val, 'cart')
		const filteredProductlist = response.data.data

		return filteredProductlist
		//const response = await new PutawayApi(apiProps).searchProduct(val);
		//return response.data.data;
	}

	const loadStorages = async (value) => {
		const val = value || storageBarcode

		// var filteredProductlist = migrationItems.filter(
		//   (item) => item.storage.label.indexOf(val) > -1
		// );

		// return filteredProductlist;
		const response = await new TransferAPI(apiProps).searchStorage(val)
		return response.data.data
	}

	const onDetect = (barcode: { barcode: string }) => {
		if (scannerType == 'product') {
			setProductBarcode(barcode)
		} else {
			setStorageBarcode(barcode)
		}
		setScannerType(null)
	}

	const checkIfProductMatches = () => {
		if (item.product.id === selectedProduct) {
			return true
		} else {
			return false
		}
	}

	const checkStorageEntry = () => {
		if (selectedStorage) {
			return true
		} else {
			return false
		}
	}

	const populateExcessPutaway = (e) => {
		if (e.target.checked) {
			setQuantity(item.quantity)
			setExcessPutaway(true)
		} else {
			setQuantity(0)
			setExcessPutaway(false)
		}
	}
	return (
		<Modal>
			<h1 className='title mt-lg'>DESTINATION LOCATION</h1>
			<div>
				<h5>Product Name : {item.product.name}</h5>
				<h5>Destination Location : {item.storage && item.storage.label}</h5>
				<h5>Quantity Assigned: {item.quantity}</h5>
			</div>

			<form className='mt-lg' onSubmit={submit}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: 5,
					}}
				>
					<input
						type='checkbox'
						value='bruh'
						style={{
							zoom: '1.4',
						}}
						onClick={(e) => populateExcessPutaway(e)}
					/>
					<label>No space for putaway</label>
				</div>
				<div className='mt-lg'>
					<label className='label'>Scan Location</label>
					<div className='grid gutter-md c-center'>
						<div className='col-10'>
							<AsyncSelect
								autoFocus
								key={storageBarcode}
								components={{ NoOptionsMessage }}
								getOptionLabel={(e) => e.label}
								getOptionValue={(e) => e.id}
								loadOptions={loadStorages}
								onChange={(value: {
									id: string
									label: string
									barcode: string
								}) => {
									setSelectedStorage(value.id)
								}}
								defaultInputValue={storageBarcode}
								defaultOptions={!!storageBarcode}
								defaultMenuIsOpen={!!storageBarcode}
							/>
						</div>
						<div className='col-2 center'>
							{/* {!checkIfStorageMatches() ? ( */}
							<button
								style={scannerBtnStyle}
								className='btn btn-orange'
								type='button'
								onClick={() => setScannerType('storage')}
							>
								<span className='fas fa-map-marker-alt' />
							</button>
							{/* ) : (
                <i className="fas fa-check green"></i>
              )} */}
						</div>
					</div>
				</div>
				<div className='mt-md'>
					<label className='label'>Scan Product</label>
					<div className='grid gutter-md c-center'>
						<div className='col-10'>
							<AsyncSelect
								autoFocus
								key={productBarcode}
								components={{ NoOptionsMessage }}
								getOptionLabel={(e) => `${e.name}: ${e.barcode}`}
								getOptionValue={(e) => e.id}
								loadOptions={loadProducts}
								onChange={(value: {
									id: string
									label: string
									barcode: string
									name: string
								}) => {
									setSelectedProduct(value.id)
								}}
								defaultInputValue={productBarcode}
								defaultOptions={!!productBarcode}
								defaultMenuIsOpen={!!productBarcode}
								//  isDisabled={putaway}
							/>
						</div>
						<div className='col-2 center'>
							{!checkIfProductMatches() ? (
								<button
									style={scannerBtnStyle}
									className='btn btn-orange'
									type='button'
									onClick={() => setScannerType('product')}
									//disabled={putaway}
								>
									<span className='fas fa-qrcode' />
								</button>
							) : (
								<i className='fas fa-check green'></i>
							)}
						</div>
					</div>
				</div>
				{excessPutaway && (
					<div className='mt-md'>
						<label className='label'>Excess Quantity</label>
						<input
							min={1}
							max={item.quantity}
							className='input input-sm'
							type='number'
							value={quantity}
							onChange={(e) => setQuantity(parseInt(e.target.value))}
							disabled={!excessPutaway}
						/>
					</div>
				)}

				<div className='mt-md'>
					{flag === false ? (
						<>
							<button
								disabled={!checkIfProductMatches() || !checkStorageEntry()}
								className='btn btn-orange w-full flex'
							>
								<div className='col-11'>MIGRATE</div>
								<div className='col-1'>
									<span className='fas fa-random' />
								</div>
							</button>
						</>
					) : (
						<button
							className='btn btn-green w-full flex'
							disabled
							style={{
								background: '#1A9C80',
							}}
						>
							<div className='col-11'>MIGRATING... </div>

							<div className='col-1'>
								<span className='fas fa-check' />
							</div>
						</button>
					)}
				</div>
			</form>
			{scannerType && (
				<>
					<div className={`full modal`} style={{ zIndex: 100 }}>
						<div className='close' onClick={() => setScannerType(null)}>
							<i className='fas fa-times'></i>
						</div>
						<Scanner onDetect={onDetect} />
					</div>
				</>
			)}
		</Modal>
	)
}

const scannerBtnStyle = {
	display: 'flex',
	justifyContent: 'center',
	height: '2.5em',
	width: '2.5em',
}

export default MigrationModal
