import { useDispatch } from 'react-redux'
import AppActions from '../redux/actions/AppAction'
import Quagga from 'quagga'

const Modal = ({ children, fullScreen = false, smallModal = false }) => {
	const dispatch = useDispatch()

	const close = () => {
		dispatch(AppActions.switchModal(null))
		//In case camera is open in modal
		//Quagga.stop() is used inside try catch as if Quagga is not opened, then it is giving error
		try {
			Quagga.stop()
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			<div className='modal-backdrop' onClick={close}></div>
			<div
				className={`${fullScreen ? 'full' : ''} modal ${
					smallModal ? 'modal-small' : ''
				}`}
			>
				<div className='close' onClick={close}>
					<i className='fas fa-times'></i>
				</div>
				<div className='body'>{children}</div>
			</div>
		</>
	)
}

export default Modal
