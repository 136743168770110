export const validFileTypesForHelpPage = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'image/svg',
	'image/gif',
	'video/mp4',
	'video/mov',
	'video/avi',
	'video/mkv',
	'video/flv',
	'video/webm',
]

export const fileSize = (size) => {
	if (size === 0) return '0 Bytes'

	const k = 1024

	const sizes = ['Bytes', 'KB', 'MB', 'BG', 'TB']

	const i = Math.floor(Math.log(size) / Math.log(k))
	return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

export const fileType = (fileName) => {
	return (
		fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
		fileName
	)
}

//Tags Colors
export const tagsColor = {
	activeTagColor: '#00C6AE',
	inactiveTagColor: '#D3D3D3',
	disabledTagColor: '#D3D3D3',
	dangerTagColor: '#FF4D4F',
	warningTagColor: '#FAAD14',
	successTagColor: '#52C41A',
}

export const ERROR_BAD_QUANTITY_MESSAGE =
	'Total Items Audited is greater than SQ are you sure about this?'
export const ERROR_BAD_QUANTITY_MESSAGE2 = 'Quantity cannot be negative'

export const SHELF_LIFE_QUANTITY_ERROR_ENGLISH =
	'Total quantity should be equal to system quantity. If not, please report deference in quantity.'
export const SHELF_LIFE_QUANTITY_ERROR_HINDI =
	'कुल मात्रा सिस्टम मात्रा के बराबर होनी चाहिए। यदि नहीं, तो कृपया report deference in quantity पर क्लिक करें।'
export const SHELF_LIFE_EXPIRY_DATES_ERROR = 'Expiry dates cannot be empty.'
export const SHELF_LIFE_NEGATIVE_QUANTITY_ERROR =
	'Quantity should be greater than or equal to 0. Please enter valid quantity.'
export const SHELF_LIFE_SYSTEM_QUANTITY_ERROR =
	'Entered quantity and system quantity can not be same.'
export const AUDIT_QUANTITY_MESSAGE_ENGLISH =
	'Audit Quantity is the actual number of items found at the location. (Includes both good and bad quantity)'
export const AUDIT_QUANTITY_MESSAGE_HINDI =
	'Audit Quantity स्थान पर पाई गई वस्तुओं की वास्तविक संख्या है। (इसमें अच्छी और बुरी दोनों मात्राएँ शामिल हैं).'
export const ERROR_FALLBACK_TEXT =
	'Something went wrong, Please try again later!'
