import { FormEvent, useState } from 'react'

import Modal from '../../../layouts/Modal'
import IndividualCharInputBox from '../../IndividualCharInputBox/IndividualCharInputBox'
import ModalHeader from '../../ModalHeader'
import { BottomSheetWrapper } from '../../styleComponents/BottomSheetWrapper'
import Scanner from '../../Scanner'
import { useDispatch, useSelector } from 'react-redux'
import { AppActionTypes } from '../../../redux/ActionTypes'
import { RootState } from '../../../redux/store'

export enum ModalType {
	STORAGE = 'STORAGE',
	PRODUCT = 'PRODUCT',
}

interface Props {
	complete: () => void
	productBarcode?: string
	storageLabel?: string
	action: (barcode?: string | number) => void
	modalType: ModalType
	newProductBarcode?: boolean
}

const NewAuditScanModal = ({
	complete,
	action,
	modalType,
	storageLabel,
	productBarcode,
	newProductBarcode = false,
}: Props) => {
	const deviceType = useSelector(
		(state: RootState) => state.appState.deviceType
	)

	const [showManualInputBox, setShowManualInputBox] = useState(
		deviceType === 'mobile' ? false : true
	)
	const [storageLabelInput, setStorageLabelInput] = useState('')
	const [showIncorrectStorageLabel, setShowIncorrectStorageLabel] =
		useState(false)
	const [showIncorrectProductId, setShowIncorrectProductId] = useState(false)

	const [productBarcodeInput, setProductBarcodeInput] = useState('')

	const dispatch = useDispatch()

	const onDetect = async (barcode: string) => {
		try {
			if (modalType === ModalType.PRODUCT && !newProductBarcode) {
				if (barcode === productBarcode.trim()) {
					complete()
					action()
					dispatch({
						type: AppActionTypes.DISPLAY_MESSAGE,
						payload: {
							text: 'Barcode has been scanned.',
							isError: false,
							milliseconds: 2500,
						},
					})
				} else {
					return dispatch({
						type: AppActionTypes.DISPLAY_MESSAGE,
						payload: {
							text: 'Wrong BarCode.',
							isError: true,
							milliseconds: 1000,
						},
					})
				}
			}
			if (modalType === ModalType.PRODUCT && newProductBarcode) {
				action(barcode)
				complete()
			}
			if (modalType === ModalType.STORAGE) {
				if (barcode !== storageLabel.trim()) {
					return dispatch({
						type: AppActionTypes.DISPLAY_MESSAGE,
						payload: {
							text: 'Wrong Barcode.',
							isError: true,
							milliseconds: 1000,
						},
					})
				} else {
					dispatch({
						type: AppActionTypes.DISPLAY_MESSAGE,
						payload: {
							text: 'Storage has been scanned, now Scan Product',
							isError: false,
							milliseconds: 2500,
						},
					})
					complete()
					action(barcode)
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	const manualInput = () => {
		setShowManualInputBox(true)
	}

	const handleBarCodeDigitsInput = (data: string) => {
		if (data != '' && data.length == 5) {
			setShowIncorrectProductId(false)
			if (+productBarcode.trim().slice(-5) === +data) {
				complete()
				action()
			} else {
				setShowIncorrectProductId(true)
			}
		}
	}

	const handleStorageLabelSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (storageLabel == null || storageLabel === 'null') {
			setShowManualInputBox(false)
			complete()
			action(storageLabelInput)
			return
		}
		if (storageLabelInput !== storageLabel.trim()) {
			setShowIncorrectStorageLabel(true)
			return
		}
		setShowManualInputBox(false)
		complete()
		action(storageLabelInput)
	}

	const handleProductBarcodeSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setShowManualInputBox(false)
		complete()
		action(productBarcodeInput)
	}

	return (
		<Modal fullScreen={true}>
			{!showManualInputBox && <Scanner onDetect={onDetect} />}
			<ModalHeader
				title={
					modalType === ModalType.PRODUCT ? 'Scan Product' : 'Scan Storage'
				}
			/>
			{modalType === ModalType.PRODUCT && !newProductBarcode && (
				<BottomSheetWrapper>
					<i
						onClick={() => setShowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input numbers
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>
								Enter the last 5 digit of the barcode
							</div>
							<IndividualCharInputBox
								amount={5}
								autoFocus
								handleOutputString={handleBarCodeDigitsInput}
								inputRegExp={/^[0-9]$/}
								type='number'
							/>
							{/* If product code doesnt match with the provided then show error text below */}
							{showIncorrectProductId && (
								<div className='pv-lg md bold red'>
									Product Code is incorrect
								</div>
							)}
						</>
					)}
				</BottomSheetWrapper>
			)}

			{modalType === ModalType.PRODUCT && newProductBarcode && (
				<BottomSheetWrapper>
					<i
						onClick={() => setShowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input numbers
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>
								Enter the digits of the barcode
							</div>

							<form onSubmit={handleProductBarcodeSubmit}>
								<input
									id='name'
									type='number'
									placeholder='Enter Product Barcode'
									onChange={(event) => {
										setProductBarcodeInput(event.target.value.trim())
									}}
									autoFocus
								/>
								<button
									type='submit'
									className='btn btn-sm btn-green mt-lg w-full'
									disabled={
										productBarcodeInput == null || productBarcodeInput === ''
									}
								>
									Assign Product Barcode
								</button>
							</form>
						</>
					)}
				</BottomSheetWrapper>
			)}

			{modalType === ModalType.STORAGE && (
				<BottomSheetWrapper>
					<i
						onClick={() => setShowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input Storage Label
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>Enter the Storage Label</div>

							<form onSubmit={handleStorageLabelSubmit}>
								<input
									id='name'
									type='text'
									placeholder='Enter Storage Label'
									onChange={(event) => {
										setStorageLabelInput(event.target.value.trim())
									}}
									autoFocus
								/>
								<button
									type='submit'
									className='btn btn-sm btn-green mt-lg w-full'
									disabled={
										storageLabelInput == null || storageLabelInput === ''
									}
								>
									Assign Storage
								</button>
							</form>
							{showIncorrectStorageLabel && (
								<div className='pv-lg md bold red'>
									Storage Label entered is incorrect
								</div>
							)}
						</>
					)}
				</BottomSheetWrapper>
			)}
		</Modal>
	)
}

export default NewAuditScanModal
