import React, { ReactChild, ReactChildren, useEffect, useState } from 'react'
import Head from 'next/head'
import Header, { NewHeader } from '../components/Header'
import { useDispatch, useSelector } from 'react-redux'
import ModalManager from '../components/ModalManager'
import { RootState } from '../redux/store'
import AppAction from '../redux/actions/AppAction'

interface Props {
	title?: unknown
	backUrl?: string
	children:
		| ReactChild
		| ReactChild[]
		| ReactChildren
		| ReactChildren[]
		| Element
		| Element[]
	newPage?: boolean
}

const mapRootState = (rootState: RootState) => {
	return {
		message: rootState.appState.message,
	}
}

const Page = ({ title, backUrl, children, newPage }: Props) => {
	const [showSideBar, setShowSidebar] = useState(false)
	const { message } = useSelector(mapRootState)
	const dispatch = useDispatch()
	// const logout = () => dispatch(AppActions.logout());
	//
	// const logoutConfirm = () => {
	//     dispatch(AppActions.switchModal(ModalName.CONFIRM, { action: logout, message: "Are you sure you want to logout?"}));
	// }

	useEffect(() => {
		if (message) {
			const timeoutId = setTimeout(
				() => dispatch(AppAction.displayMessage(null, false)),
				message.milliseconds
			)
			return () => clearTimeout(timeoutId)
		}
	}, [message])

	return (
		<React.Fragment>
			<Head>
				<title>Mera 1K</title>
				<meta
					name='viewport'
					content='initial-scale=1.0, maximum-scale=1.0, user-scalable=0, width=device-width'
				/>
				<link
					href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'
					rel='stylesheet'
				/>
				<link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' />
				<link rel='icon' href='/favicon.ico' type='image/x-icon' />
				<link rel='manifest' href='/manifest.json' />
			</Head>
			<ModalManager />

			{/*<Sidebar openSidebar={() => setShowSidebar(!showSideBar)} show={showSideBar} >*/}
			{/*    <a href="#">Link 1</a>*/}
			{/*    <a href="#">Link 2</a>*/}
			{/*    <a href="#">Link 3</a>*/}
			{/*    <a href="#">Link 4</a>*/}
			{/*</Sidebar>*/}
			{newPage ? (
				<NewHeader
					openSidebar={() => setShowSidebar(!showSideBar)}
					title={title}
					backUrl={backUrl}
				/>
			) : (
				<Header
					openSidebar={() => setShowSidebar(!showSideBar)}
					title={title}
					backUrl={backUrl}
				/>
			)}

			{message && (
				<div className='toast'>
					<div className='toast-body'>
						{message.isError === true ? (
							<>
								<img src='/images/error.svg' alt='error' width='50' />
								<div className='margin-y-8 xl bold'>Error!</div>
							</>
						) : (
							<>
								<img src='/images/success1.svg' alt='Success' width='50' />
								<div className='margin-y-8 xl bold'>Success!</div>
							</>
						)}

						<div className='md normal'>{message.text}</div>
					</div>
				</div>
			)}
			<div className='content'>
				<div className={newPage ? 'newContainer' : 'container'}>{children}</div>
			</div>
		</React.Fragment>
	)
}

export default Page
