import React, { useEffect, useState } from 'react'
import Webcam from 'react-webcam'
import * as zbarWasm from 'zbar.wasm'
const Scanner = ({ onDetect }) => {
	const webcamRef = React.useRef(null)
	const [barcode, setBarcode] = useState(null)

	const getImageData = async (imageSrc) => {
		// console.log(
		//     document
		//         .getElementById("webcam-container")
		//         .contains(document.getElementById("screenshot-image"))
		// );
		if (
			document
				.getElementById('webcam-container')
				.contains(document.getElementById('screenshot-image'))
		) {
			const imageElement = document.getElementById(
				'screenshot-image'
			) as HTMLImageElement
			imageElement.src = imageSrc
			// console.log("imageElementxxx", imageElement);
			const imageData = await drawCanvas(imageElement).then((res) => {
				return res
			})
			console.log('image data1 ', imageData)
			return imageData
		} else {
			const image = new Image()
			image.src = imageSrc
			await image.onload
			image.id = 'screenshot-image'
			document.getElementById('webcam-container').appendChild(image)
			// console.log("imageElement", image.height);
			// console.log("imageElementxxx", image);
			const imageData = await drawCanvas(image).then((res) => {
				// console.log("checking response", res);
				return res
			})
			// console.log("image data2 ", imageData);
			return imageData
		}
	}

	const drawCanvas = async (image) => {
		if (document.body.contains(document.getElementById('scanner-canvas'))) {
			await document.getElementById('scanner-canvas').remove()
		}

		try {
			const mycanvas = document.createElement('canvas')
			mycanvas.id = 'scanner-canvas'
			mycanvas.height = image.height
			mycanvas.width = image.width
			document.getElementById('webcam-container').appendChild(mycanvas)
			const ctx = mycanvas.getContext('2d')
			await ctx.drawImage(image, 0, 0)
			return ctx.getImageData(0, 0, image.width, image.height)
		} catch (error) {
			console.log('error : ', error)
		}
	}

	const capture = React.useCallback(async () => {
		// console.log("xx", webcamRef);

		try {
			if (webcamRef) {
				const imageSrc = webcamRef.current.getScreenshot()

				const imageData = await getImageData(imageSrc).then((res) => {
					return res
				})
				// console.log("in capture", imageData);
				// let result = document.getElementById("result");
				// let canvas = document.getElementById("c"),
				//   ctx = canvas.getContext("2d");
				// const image = new Image();
				// image.onload = async () => {
				//   ctx.imageSmoothingEnabled = false;
				//   ctx.clearRect(0, 0, canvas.width, canvas.height);
				//   await ctx.drawImage(image, 0, 0, 1000, 2000);
				// };
				// image.src = imageSrc;
				// // console.log("img", image)
				// await image.decode();
				// const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
				const symbols: (zbarWasm.Symbol & { rawData?: string })[] =
					await zbarWasm.scanImageData(imageData)
				// console.log("imagedata1", imageData);
				symbols.forEach(
					(s: zbarWasm.Symbol & { rawData: string }) => (s.rawData = s.decode())
				)
				// console.log("results1", symbols);
				if (symbols[0]) {
					// console.log("results", symbols[0].rawData);
					if (symbols[0].rawData) {
						// alert(symbols[0].rawData);
						// alert("quality " + symbols[0].quality);
						setBarcode(symbols[0].rawData)
						onDetect(symbols[0].rawData.trim())
					}
				}

				// result.innerText = JSON.stringify(symbols, null, 2);
			}
		} catch (error) {
			console.log('error: ', error)
		}
	}, [webcamRef])

	// useEffect(() => {
	//   (async () => {
	//     const img = document.getElementById("img"),
	//       result = document.getElementById("result"),
	//       canvas = document.createElement("canvas"),
	//       context = canvas.getContext("2d");

	//     await img.decode();
	//     canvas.width = img.naturalWidth;
	//     canvas.height = img.naturalHeight;
	//     context.drawImage(img, 0, 0);

	//     const imageData = context.getImageData(0, 0, canvas.width, canvas.height),
	//       symbols = await zbarWasm.scanImageData(imageData);
	//       console.log("imagedata2", imageData)
	//     symbols.forEach((s) => (s.rawData = s.decode()));
	//     result.innerText = JSON.stringify(symbols, null, 2);
	//   })();
	// }, []);

	useEffect(() => {
		const interval = setInterval(() => {
			// console.log("Logs every half second");
			// if (!barcode) {
			//     capture();
			// }
			capture()
		}, 500)

		return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
	}, [barcode])

	return (
		<div className='App'>
			<section id='App-section'>
				<pre id='result'></pre>
			</section>
			<div id='webcam-container'>
				<Webcam
					audio={false}
					ref={webcamRef}
					screenshotFormat='image/jpeg'
					// forceScreenshotSourceSize
					videoConstraints={{
						height: 720,
						width: 1280,
						facingMode: 'environment',
					}}
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						left: '50%',
						marginLeft: '-50%',
						objectFit: 'cover',
						objectPosition: 'center',
					}}
				/>
				{/* <canvas style={{border: "solid 1px red"}}  id="c" width={1000} height={2000} /> */}
			</div>
		</div>
	)
}

export default Scanner
