import Modal from '../../layouts/Modal'
import { ModalProps } from '../ModalManager'
import CustomPrimaryButton from '../buttons/CustomPrimaryButton'

interface Props extends ModalProps {
	action: () => void
	message: string
	content?: string
}

const ConfirmModal = ({ complete, action, message, content }: Props) => {
	const success = () => {
		action()
		complete()
	}

	return (
		<Modal smallModal={true}>
			<h1 className='title center'>{message}</h1>
			{content && <div className='md mt-md center'>{content}</div>}
			<div className='mt-lg grid m-center'>
				<div className='col-4'>
					<CustomPrimaryButton
						label='Done'
						handleClick={success}
						isDisabled={false}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmModal
