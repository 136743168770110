import Api from './Api'
import qs from 'qs'
import { transferlist } from './dummydata'

export default class TransferAPI extends Api {
	init = async () => {
		return transferlist

		// return await this.getClient().post(url);  /migrations/{id}/products
	}
	//TODO change stubs
	loadItems = async () => {
		const url = this.getUrl('migrations/migration')
		//const items =  transferlist.filter(item=> item.id == id);
		//return  items[0];
		return await this.getClient().get(url)
	}

	loadCart = async () => {
		const url = this.getUrl('migrations/migration/carts')
		// c//onst items =  transferlist.filter(item=> item.id == id);
		//return  items[0];
		return await this.getClient().get(url)
	}

	searchProduct = async (term: number, type?: string) => {
		const url = this.getUrl(
			`migrations/migration/search/products?term=${term}${
				type ? `&type=cart` : ''
			}`
		)
		return await this.getClient().get(url)
	}

	searchStorage = async (value) => {
		const url = this.getUrl(`search/storages?term=${value}`)
		return await this.getClient().get(url)
	}

	addItemToCart = async (params) => {
		const url = this.getUrl('migrations/migration/carts/add-item')
		return await this.getClient().post(url, qs.stringify(params))
	}

	getCart = async () => {
		const url = this.getUrl(`transfer/getcart`)

		return await this.getClient().get(url)
	}

	startMigration = async () => {
		const url = this.getUrl('migrations/migration/start')

		return await this.getClient().post(url)
	}

	// closeMigration = async(id ) => {
	//     const url = this.getUrl('putaway/'+id+'/close');

	//     return await this.getClient().post(url);
	// }

	migrateItem = async (params) => {
		//console.lg
		const url = this.getUrl('migrations/migration/place-item')
		return await this.getClient().post(url, qs.stringify(params))
	}
}
