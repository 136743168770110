export const source_storage = {
	id: 1,
	type: 'default',
	category: 'floor',
	label: 'L0-A1-01-A',
}

export const destination_storage = {
	id: 3,
	type: 'default',
	category: 'floor',
	label: 'L0-A1-03-A',
}

export const dummyProduct = {
	id: 123421,
	name: 'dummy product 1',
	barcode: '12345434322444',
}

export const dummyProduct2 = {
	id: 123422,
	name: 'dummy product 2',
	barcode: '22345434322',
}

export const dummyProduct3 = {
	id: 123422,
	name: 'dummy product 3',
	barcode: '32345434322',
}

export const dummydata = [
	{
		id: 1,
		auditId: 1,
		quantity: 112,
		status: 'pending',
		source_storage: source_storage,
		destination_storage: destination_storage,
		product: dummyProduct,
	},

	{
		id: 2,
		auditId: 1,
		quantity: 100,
		status: 'pending',
		source_storage: source_storage,
		destination_storage: destination_storage,
		product: dummyProduct2,
	},

	{
		id: 3,
		auditId: 1,
		quantity: 100,
		status: 'pending',
		source_storage: source_storage,
		destination_storage: destination_storage,
		product: dummyProduct3,
	},

	{
		id: 4,
		auditId: 1,
		quantity: 100,
		status: 'pending',
		source_storage: source_storage,
		destination_storage: destination_storage,
		product: dummyProduct2,
	},

	{
		id: 5,
		auditId: 1,
		quantity: 100,
		status: 'pending',
		source_storage: source_storage,
		destination_storage: destination_storage,
		product: dummyProduct3,
	},
]

export const dummydata2 = [
	{
		id: 1,
		auditId: 2,
		systemQuantity: 112,
		status: 'pending',
		storage: source_storage,
		product: dummyProduct,
	},

	{
		id: 2,
		auditId: 2,
		systemQuantity: 75,
		status: 'pending',
		storage: source_storage,
		product: dummyProduct2,
	},

	{
		id: 3,
		auditId: 2,
		systemQuantity: 86,
		status: 'pending',
		storage: source_storage,
		product: dummyProduct3,
	},

	{
		id: 4,
		auditId: 2,
		systemQuantity: 88,
		status: 'pending',
		storage: source_storage,
		product: dummyProduct2,
	},

	{
		id: 5,
		auditId: 2,
		systemQuantity: 90,
		status: 'pending',
		storage: source_storage,
		product: dummyProduct3,
	},
]

export const transferlist = [
	{
		id: 1,
		name: 'Product AUdit',
		status: 'open',
		created_at: '',
		type: 'Item',
		items: dummydata,
		locations: dummydata2,
	},
	{
		id: 2,
		name: 'Location AUdit',
		status: 'open',
		created_at: '',
		type: 'Location',
		items: dummydata,
		locations: dummydata2,
	},
	{
		id: 3,
		name: 'Product AUdit',
		status: 'open',
		created_at: '',
		type: 'Item',
		items: dummydata,
		locations: dummydata2,
	},
	{
		id: 4,
		name: 'Location AUdit',
		status: 'open',
		created_at: '',
		type: 'Location',
		items: dummydata,
		locations: dummydata2,
	},
]

export const groupItems = [
	{
		id: 1,
		//'quantity':112,
		status: 'pending',
		items: dummydata,
		locations: destination_storage,
	},

	{
		id: 2,
		//'quantity':112,
		status: 'pending',
		items: dummydata2,
		locations: destination_storage,
	},

	{
		id: 3,
		//'quantity':112,
		status: 'pending',
		items: source_storage,
		locations: destination_storage,
	},
	{
		id: 4,
		//'quantity':112,
		status: 'pending',
		items: source_storage,
		locations: destination_storage,
	},
	{
		id: 4,
		//'quantity':112,
		status: 'pending',
		items: [dummyProduct, dummyProduct2, dummyProduct3],
		locations: destination_storage,
	},
]

const groupProducts = [
	{
		product: dummyProduct,
		numberOfStorages: 3,
		totalQuantity: 34,
	},
	{
		product: dummyProduct2,
		numberOfStorages: 3,
		totalQuantity: 54,
	},
	{
		product: dummyProduct3,
		numberOfStorages: 3,
		totalQuantity: 30,
	},
	{
		product: dummyProduct,
		numberOfStorages: 3,
		totalQuantity: 30,
	},
]

const groupLocations = [
	{
		storage: source_storage,
		numberOfProducts: 8,
		totalQuantity: 50,
	},
	{
		storage: destination_storage,
		numberOfProducts: 10,
		totalQuantity: 100,
	},
	{
		storage: source_storage,
		numberOfProducts: 8,
		totalQuantity: 30,
	},
	{
		storage: destination_storage,
		numberOfProducts: 3,
		totalQuantity: 40,
	},
]

export const audit1 = {
	id: 1,
	name: 'New ProductWise Audit Name',
	type: 'product',
	status: 'started',
	distinctProducts: groupProducts,
}

export const audit2 = {
	id: 2,
	name: 'New ProductWise Audit Name',
	type: 'product',
	status: 'started',
	distinctProducts: groupProducts,
}

export const audit3 = {
	id: 3,
	name: 'New ProductWise Audit Name',
	type: 'location',
	status: 'started',
	distinctStorages: groupLocations,
}

export const audit4 = {
	id: 4,
	name: 'New ProductWise Audit Name',
	type: 'location',
	status: 'started',
	distinctStorages: groupLocations,
}
export const auditlist = [audit1, audit2, audit3, audit4]

export const reasons = [
	{
		reason: {
			id: 1,
			sourceType: 'audit',
			reasonType: 'create',
			text: 'expired',
		},
	},
	{
		reason: {
			id: 2,
			sourceType: 'audit',
			reasonType: 'create',
			text: 'Damaged',
		},
	},
]
