import { useState } from 'react'

import Modal from '../../../layouts/Modal'
import ModalHeader from '../../ModalHeader'
import { BottomSheetWrapper } from '../../styleComponents/BottomSheetWrapper'
import Quagga from 'quagga'
import Scanner from '../../Scanner'
import { RootState, useThunkyDispatch } from '../../../redux/store'
import AppAction from '../../../redux/actions/AppAction'
import { useSelector } from 'react-redux'

const LocationScanModal = ({ complete, action, storageLabelFromApi }) => {
	const dispatch = useThunkyDispatch()
	const deviceType = useSelector(
		(state: RootState) => state.appState.deviceType
	)

	const [showManualInputBox, setShowManualInputBox] = useState(
		deviceType == 'mobile' ? false : true
	)
	const [newStorageLabel, setNewStorageLabel] = useState(null)
	const [showIncorrectLabel, setShowIncorrectLabel] = useState(false)

	const onDetect = (barcode: string) => {
		verifyStorageLabel(barcode)
	}

	const manualInput = () => {
		setShowManualInputBox(true)
	}

	const verifyStorageLabel = (storageLabelFromScan: string) => {
		if (storageLabelFromApi === storageLabelFromScan) {
			setShowIncorrectLabel(false)
			try {
				Quagga.stop()
			} catch (error) {
				console.log(error)
			}
			complete()
			action()
		} else {
			if (manualInput) {
				setShowIncorrectLabel(true)
			} else {
				dispatch(
					AppAction.displayMessage('Incorrect storage Label', true, 2000)
				)
			}
		}
	}

	const handleStorageChange = () => {
		verifyStorageLabel(newStorageLabel)
	}

	return (
		<Modal fullScreen={true}>
			{!showManualInputBox && <Scanner onDetect={onDetect} />}
			<ModalHeader title={'Scan Storage'} />

			<BottomSheetWrapper>
				<i
					onClick={() => setShowManualInputBox(false)}
					className='fas fa-camera'
					style={{ float: 'right', fontSize: 20 }}
				></i>
				<div className='sm bold black underline' onClick={manualInput}>
					Cant Scan? Try to input Storage Label
				</div>
				{showManualInputBox && (
					<>
						<div className='pv-lg md bold'>Enter the Storage Label</div>

						<input
							id='name'
							type='text'
							placeholder='Enter Storage Label'
							onChange={(event) => {
								setNewStorageLabel(event.target.value)
							}}
							autoFocus
						/>
						{/* If location label doesnt match with the provided then show error text below*/}
						{showIncorrectLabel ? (
							<div className='pv-lg md bold red'>
								Location Label is incorrect
							</div>
						) : (
							<></>
						)}
						<button
							className='btn btn-sm btn-green mt-lg w-full'
							onClick={() => handleStorageChange()}
						>
							Go
						</button>
					</>
				)}
			</BottomSheetWrapper>
		</Modal>
	)
}

export default LocationScanModal
