import Api from './Api'
import qs from 'qs'
import { PutawayType } from '../types/PutawayItem'

export default class PutawayApi extends Api {
	init = async () => {
		const url = this.getUrl('putaway/init')
		return await this.getClient().post(url)
	}

	searchProduct = async (term: number, type?: string) => {
		const url = this.getUrl(
			`putaway/product/search?term=${term}${type ? `&type=cart` : ''}`
		)
		return await this.getClient().get(url)
	}

	searchStorage = async (value) => {
		const url = this.getUrl(`search/storages?term=${value}`)
		return await this.getClient().get(url)
	}

	assignStorage = async (params) => {
		const url = this.getUrl(`putaway/place-item`)
		return await this.getClient().post(url, qs.stringify(params))
	}

	loadItems = async (putawayType: PutawayType) => {
		const url = this.getUrl(`putaway/${putawayType}/carts`)
		return await this.getClient().get(url)
	}

	addItem = async (params, putawayType: PutawayType) => {
		const url = this.getUrl(`${putawayType}/item`)
		return await this.getClient().post(url, qs.stringify(params))
	}

	start = async (putawayType: PutawayType) => {
		const url = this.getUrl(`migrations/${putawayType}/start`)
		return await this.getClient().post(url)
	}

	addToCart = async (params) => {
		const url = this.getUrl(`putaway/add-to-cart`)
		return await this.getClient().post(url, qs.stringify(params))
	}

	loadPutawayAssignedItems = async (putawayType: string) => {
		const url = this.getUrl(`putaway/get/${putawayType}`)
		return await this.getClient().get(url)
	}

	// close = async(id: number) => {
	//     const url = this.getUrl("migrations/putaway/close");
	//     return await this.getClient().post(url);
	// }
}
