import { AxiosError } from 'axios'
import { badQuantityType } from '../components/newAudit/ProductAndLocationTypeAudit'
import { ERROR_FALLBACK_TEXT } from './constants'

export const formatDateTime = (timeStamp) => {
	if (!timeStamp) return ''
	try {
		const date = new Date(timeStamp).toDateString()
		const time = new Date(timeStamp).toLocaleTimeString()
		const formattedDateTime = `${date} ${time !== 'Invalid Date' ? time : ''}`
		if (formattedDateTime.includes('Invalid Date')) return ''
		return formattedDateTime
	} catch (error) {
		new Date(timeStamp).toUTCString()
	}
}

export const formatDate = (timeStamp) => {
	if (!timeStamp) return ''
	try {
		return new Date(timeStamp).toDateString()
	} catch (error) {
		new Date(timeStamp).toDateString()
	}
}

export const getErrorText = (e: AxiosError) => {
	try {
		if (typeof e?.response?.data === 'string') {
			return e.response.data
		}

		if (typeof e?.response?.data?.message === 'string') {
			return e.response.data.message
		}

		if (typeof e?.response?.data?.error === 'string') {
			return e.response.data.error
		}

		if (
			Array.isArray(e.response?.data?.message) &&
			e.response?.data?.message?.length > 0
		) {
			const errorMessageArray = e.response?.data?.message
			return errorMessageArray
				.map((item: Record<string, string>) => {
					return item.message
				})
				.join(' & ')
		}
		const error =
			e?.response?.data?.error ||
			e?.response?.data?.message ||
			ERROR_FALLBACK_TEXT

		return error
	} catch (error) {
		return ERROR_FALLBACK_TEXT
	}
}

export const changeYYYYMMDDToDDMMYYYY = (date: string) => {
	try {
		const split = date.split('-')
		return split[2] + '-' + split[1] + '-' + split[0]
	} catch (error) {
		console.log('Error: ', error)
	}
	return ''
}

export const getSuccessText = (data: { message: string }) => {
	const msg = data?.message || 'Successfully Done'
	return msg
}

export const checkNegativeQuantity = (badQuantity: badQuantityType[]) => {
	for (let i = 0; i < badQuantity.length; i++) {
		if (+badQuantity[i].quantity < 0) {
			return true
		}
	}
	return false
}
