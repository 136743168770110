import { useState } from 'react'

import Modal from '../../../layouts/Modal'
import IndividualCharInputBox from '../../IndividualCharInputBox/IndividualCharInputBox'
import ModalHeader from '../../ModalHeader'
import { BottomSheetWrapper } from '../../styleComponents/BottomSheetWrapper'
import Quagga from 'quagga'
import Scanner from '../../Scanner'
import { useDispatch, useSelector } from 'react-redux'
import { AppActionTypes } from '../../../redux/ActionTypes'
import { RootState } from '../../../redux/store'

const IATScanModal = ({
	complete,
	productBarcode,
	action,
	productModal = false,
}) => {
	const deviceType = useSelector(
		(state: RootState) => state.appState.deviceType
	)

	const [showManualInputBox, setShowManualInputBox] = useState(
		deviceType === 'mobile' ? false : true
	)
	const [newStorageLabel, setNewStorageLabel] = useState(null)
	const [showIncorrectProductId, setShowIncorrectProductId] = useState(false)

	const dispatch = useDispatch()

	const onDetect = async (barcode: string) => {
		try {
			if (productModal) {
				// console.log("Product BarCode", barcode);
				// console.log("Barcode froM api", productBarcode);
				if (barcode === productBarcode) {
					complete()
					action()
					Quagga.stop()
				} else {
					dispatch({
						type: AppActionTypes.DISPLAY_MESSAGE,
						payload: {
							text: 'Wrong BarCode',
							isError: true,
							milliseconds: 1000,
						},
					})
				}
			} else {
				//FOR STORAGE SCAN
				//SHOWING MESSAGE THAT STORAGE HAS BEEN SCANNED
				dispatch({
					type: AppActionTypes.DISPLAY_MESSAGE,
					payload: {
						text: 'Storage has been scanned, now Scan Product',
						isError: false,
						milliseconds: 2500,
					},
				})
				complete()
				// console.log("location BarCode", barcode);
				action(barcode)
				Quagga.stop()
			}
		} catch (error) {
			console.log(error)
		}
		// complete();
	}

	const manualInput = () => {
		setShowManualInputBox(true)
	}

	const handleBarCodeDigitsInput = (data: string) => {
		if (data != '' && data.length == 5) {
			setShowIncorrectProductId(false)
			if (+productBarcode.slice(-5) === +data) {
				try {
					Quagga.stop()
				} catch (error) {
					console.log(error)
				}
				complete()
				action()
			} else {
				setShowIncorrectProductId(true)
			}
		}
	}

	const handleStorageChange = async () => {
		setShowManualInputBox(false)
		complete()
		action(newStorageLabel)
	}

	// const isCorrectStorageLabel = async ({
	//     storageLabel,
	//     productId,
	// }: {
	//     storageLabel: string;
	//     productId: string;
	// }) => {
	//     if (!storageLabel) return false;

	//     const data = await dispatch(
	//         IATAction.checkCorrectStorageLabel({
	//             storageLabel,
	//             productId,
	//         })
	//     );

	//     if (data) return true;
	// };

	return (
		<Modal fullScreen={true}>
			{!showManualInputBox && <Scanner onDetect={onDetect} />}
			<ModalHeader title={productModal ? 'Scan Product' : 'Scan Storage'} />

			{productModal && (
				<BottomSheetWrapper>
					<i
						onClick={() => setShowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input numbers
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>
								Enter the last 5 digit of the barcode
							</div>
							<IndividualCharInputBox
								amount={5}
								autoFocus
								handleOutputString={handleBarCodeDigitsInput}
								inputRegExp={/^[0-9]$/}
								type='number'
							/>
							{/* If product code doesnt match with the provided then show error text below*/}
							{showIncorrectProductId ? (
								<div className='pv-lg md bold red'>
									Product Code is incorrect
								</div>
							) : (
								<></>
							)}
						</>
					)}
				</BottomSheetWrapper>
			)}

			{!productModal && (
				<BottomSheetWrapper>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input Storage Label
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>Enter the Storage Label</div>

							<input
								id='name'
								type='text'
								placeholder='Enter Storage Label'
								onChange={(event) => {
									setNewStorageLabel(event.target.value)
								}}
								autoFocus
							/>
							<button
								className='btn btn-sm btn-green mt-lg w-full'
								onClick={() => handleStorageChange()}
							>
								Assign Storage
							</button>
						</>
					)}
				</BottomSheetWrapper>
			)}
		</Modal>
	)
}

export default IATScanModal
