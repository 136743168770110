import Modal from '../../../layouts/Modal'
import React, { FormEvent, useState } from 'react'
import AsyncSelect from 'react-select/async'
import PutawayApi from '../../../apis/PutawayApi'
import { useSelector } from 'react-redux'
import { RootState, useThunkyDispatch } from '../../../redux/store'
import Toolkit from '../../../utils/Toolkit'
import { AppActionTypes } from '../../../redux/ActionTypes'
import debounce from 'lodash/debounce'
import { getErrorText } from '../../../utils/helperFunctions'

const AssignStorageModal = ({ complete, item, assignStorage }) => {
	const [selectedStorage, setSelectedStorage] = useState(null)
	const [quantity, setQuantity] = useState<number>(item.quantity)

	const dispatch = useThunkyDispatch()

	const handleChange = (value) => {
		setSelectedStorage(value.id)
	}

	const rootState = useSelector((state: RootState) => state)
	const apiProps = Toolkit.mapStateToApiProps(rootState)

	const loadStorages = (value, callback) => {
		new PutawayApi(apiProps)
			.searchStorage(value)
			.then((res) => callback(res.data.data))
			.catch((err) => {
				console.log(getErrorText(err))
				return callback([])
			})
	}

	const debouncedLoadStorages = debounce(loadStorages, 500)

	const submit = async (e: FormEvent) => {
		e.preventDefault()

		if (quantity && quantity > 0 && selectedStorage) {
			const params = {
				putaway_cart_id: item.id,
				destination_storage_id: selectedStorage,
				placed_quantity: quantity,
				// variant: item.sku.substring(item.sku.indexOf('-') + 1),
			}
			assignStorage(params)
			complete()
			return
		}
		dispatch({
			type: AppActionTypes.DISPLAY_MESSAGE,
			payload: {
				text: 'Enter a valid Quanity and Storage',
				isError: true,
				milliseconds: 2000,
			},
		})
	}

	return (
		<Modal>
			<h1 className='title mt-lg'>{item.product.name}</h1>
			<p>
				Quantity: {item.quantity}
				{/* {item.sku.substring(item.sku.indexOf('-') + 1)}) */}
			</p>
			<p>Barcode: {item.product.barcode}</p>
			<form className='mt-lg' onSubmit={submit}>
				<div>
					<label className='label'>Assign to Storage</label>
					<AsyncSelect
						autoFocus
						getOptionLabel={(e) => e.label}
						getOptionValue={(e) => e.id}
						loadOptions={debouncedLoadStorages}
						onChange={handleChange}
					/>
				</div>
				<div>
					<label className='label'>Quantity</label>
					<input
						value={quantity}
						max={item.quantity}
						min={0}
						onChange={(e) => {
							setQuantity(
								isNaN(parseInt(e.target.value))
									? null
									: parseInt(e.target.value)
							)
						}}
						className='input input-sm'
						type='number'
					/>
				</div>
				<div className='mt-lg center'>
					<button className='btn btn-green'>ASSIGN</button>
				</div>
			</form>
		</Modal>
	)
}

export default AssignStorageModal
