import Modal from '../../../layouts/Modal'
import { ModalProps } from '../../ModalManager'
import style from './iatConfirmModal.module.css'

interface Props extends ModalProps {
	action: () => void
	message: string
	content?: string
}

const IATConfirmModal = ({ complete, action, message, content }: Props) => {
	const discard = () => {
		complete()
	}

	const success = () => {
		action()
		complete()
	}

	return (
		<Modal smallModal={true}>
			<h1 className='title center'>{message}</h1>
			{content && <div className='md mt-md center black'>{content}</div>}
			<div className='mt-lg grid m-space-evenly'>
				<div className='col-4'>
					<button
						className={`btn w-full h-full ${style.yes}`}
						onClick={success}
					>
						Yes
					</button>
				</div>
				<div className='col-4'>
					<button className={`btn w-full h-full ${style.no}`} onClick={discard}>
						No
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default IATConfirmModal
