import React, { useState } from 'react'
import Modal from '../../../layouts/Modal'
import CustomPrimaryButton from '../../buttons/CustomPrimaryButton'
import { ModalProps } from '../../ModalManager'

interface Props extends ModalProps {
	action: (param: string) => void
	message: string
}

const IncorrectProductInfoModal = ({ complete, action, message }: Props) => {
	const [wrongInfo, setWrongInfo] = useState('')

	const success = () => {
		action(wrongInfo)
		complete()
	}

	const handleWrongInfoChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setWrongInfo(event.target.value)
	}

	return (
		<Modal smallModal={true}>
			<h1 className='title mt-lg'>{message}</h1>
			<div className='mt-lg'>
				<input
					className='input input-sm'
					type='text'
					value={wrongInfo}
					onChange={handleWrongInfoChange}
					placeholder='Type'
				/>
			</div>
			<div className='mt-md grid gutter-around'>
				<div className='col-5'>
					<CustomPrimaryButton
						label='Raise Issue'
						handleClick={success}
						isDisabled={wrongInfo.trim() === '' ? true : false}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default IncorrectProductInfoModal
