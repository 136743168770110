import Modal from "../../layouts/Modal";
import { ModalProps } from "../ModalManager";
import {
  PrimaryButton,
  PrimaryOutlineButton,
} from "../NewDesignComponents/NewButtons";

interface Props extends ModalProps {
  action: (id: string | number) => void;
  message: string;
  content?: string;
  id: number | string;
  confirmButtonText?: string;
  discardButtonText?: string;
}

const NewConfirmModal = ({
  complete,
  action,
  message,
  content,
  id,
  confirmButtonText = "Yes",
  discardButtonText = "No",
}: Props) => {
  const discard = () => {
    complete();
  };

  const success = () => {
    action(id);
    complete();
  };

  return (
    <Modal smallModal={true}>
      <h1 className="title center">{message}</h1>
      {content && <div className="md mt-md center black">{content}</div>}
      <div className="buttonWrapper mt-md">
        <PrimaryOutlineButton
          text={discardButtonText}
          handleClick={discard}
          id="none"
        />
        <PrimaryButton
          text={confirmButtonText}
          handleClick={success}
          id="none"
        />
      </div>
    </Modal>
  );
};

export default NewConfirmModal;
