import styled from 'styled-components'

const TopBar = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 70px;

	.height {
		height: 100%;
	}
`

const PageTitle = ({ title }) => {
	return (
		<>
			<div className='col-3'></div>
			<div className='col-6 center'>
				<div className='semi-bold'>{title}</div>
			</div>
			<div className='col-3 right'></div>
		</>
	)
}

const ModalHeader = ({ title }: { title: string }) => {
	return (
		<TopBar className='header'>
			<div className='grid gutter-between c-center white p-md height'>
				<PageTitle title={title} />
			</div>
		</TopBar>
	)
}

export default ModalHeader
