import { ThunkyAction } from '../store'
import AuditApi from '../../apis/AuditApi'
import Toolkit from '../../utils/Toolkit'
import { AppActionTypes } from '../ActionTypes'
import {} from '../../types/PutawayItem'
import { AuditItem } from '../../types/AuditItem'
import Audit from '../../types/Audit'

//TO DO changes
const load = (id): ThunkyAction<Promise<Audit>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).loadGroupItems(id)
		//return response;
		dispatch({
			type: AppActionTypes.LOAD_AUDIT_LIST,
			payload: { auditlist: response },
		})
		return response.data.data
		//return response.data.data;
	}
}

const loadItems = (
	auditid,
	type,
	value
): ThunkyAction<Promise<AuditItem[] | []>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).loadItems(
			auditid,
			type,
			value
		)
		return response.data.data
		//return response.data.data;
	}
}

const loadProduct = (auditid, id): ThunkyAction<Promise<AuditItem>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).loadProduct(auditid, id)
		//return response;
		return response.data.data
	}
}

const inputItem = (auditid, pid, apiparams): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).inputAuditItem(
			auditid,
			pid,
			apiparams
		)
		//return response;
		return response.data.data
	}
	//return ;
}
// TO DO to update the api signature once
const submitItem = (auditid, pid): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).submitAuditItem(auditid, pid)
		//return response;
		return response.data.data
	}
	//return ;
}

const addExtraInventory = (
	auditid,
	apiparams
): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).addExtraEnvontory(
			auditid,
			apiparams
		)
		//return response;
		return response.data.data
	}
	//return ;
}

const getReasons = (): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).getReasons()
		// return response;
		return response.data.data
	}
}

const searchProduct = (barcode: number): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).searchProduct(barcode)
		// return response;
		return response.data.data
	}
}

const getProduct = (productId: number): ThunkyAction<Promise<unknown>> => {
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		const response = await new AuditApi(apiProps).getProduct(productId)
		// return response;
		return response.data.data
	}
}

const AuditAction = {
	load,
	loadProduct,
	loadItems,
	getReasons,
	inputItem,
	submitItem,
	searchProduct,
	getProduct,
	addExtraInventory,
}
export default AuditAction
