import Modal from '../../../layouts/Modal'
import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import Toolkit from '../../../utils/Toolkit'
import { useSelector } from 'react-redux'
import { RootState, useThunkyDispatch } from '../../../redux/store'
import PutawayApi from '../../../apis/PutawayApi'
import { components } from 'react-select'
import Scanner from '../../Scanner'
import Loader from '../../Loader'
import { AppActionTypes } from '../../../redux/ActionTypes'
import debounce from 'lodash/debounce'
import { getErrorText } from '../../../utils/helperFunctions'

const AddItemModal = ({ complete, onSubmit, item, loading }) => {
	const [selectedProduct, setSelectedProduct] = useState<string>(null)
	const [quantity, setQuantity] = useState<number | null>(item.pending_quantity)
	const [scannerType, setScannerType] = useState(null)
	const [productBarcode, setProductBarcode] = useState(undefined)

	const dispatch = useThunkyDispatch()

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span>No Options Found</span>
			</components.NoOptionsMessage>
		)
	}

	const rootState = useSelector((state: RootState) => state)
	const apiProps = Toolkit.mapStateToApiProps(rootState)

	const loadProducts = (value, callback) => {
		const val = value || productBarcode
		new PutawayApi(apiProps)
			.searchProduct(val)
			.then((res) => callback(res.data))
			.catch((err) => {
				console.log(getErrorText(err))
				return callback([])
			})
	}

	const onDetect = (barcode: { barcode: string }) => {
		if (scannerType == 'product') {
			setProductBarcode(barcode)
		}
		setScannerType(null)
	}

	const checkIfProductMatches = () => {
		if (item.product.id === selectedProduct) {
			return true
		} else {
			return false
		}
	}

	const submit = (e) => {
		e.preventDefault()

		if (quantity && quantity > 0) {
			onSubmit(quantity)
			complete()
			return
		}

		dispatch({
			type: AppActionTypes.DISPLAY_MESSAGE,
			payload: {
				text: 'Enter a valid Quanity',
				isError: true,
				milliseconds: 2000,
			},
		})
	}

	const debouncedLoadProducts = debounce(loadProducts, 500)

	return (
		<Modal>
			<h1 className='title mt-lg'>SOURCE LOCATION</h1>
			{loading && <Loader />}
			<div>
				<h5>Product Name : {item.product.name}</h5>
				<h5>Quantity Assigned: {item.pending_quantity}</h5>
			</div>

			<form className='mt-lg' onSubmit={submit}>
				<div className='mt-md'>
					<label className='label'>Scan Product</label>
					<div className='grid gutter-md c-center'>
						<div className='col-10'>
							<AsyncSelect
								autoFocus
								key={productBarcode}
								components={{ NoOptionsMessage }}
								getOptionLabel={(e) => `${e.name}: ${e.barcode}`}
								getOptionValue={(e) => e.id}
								// loadOptions={loadProducts}
								loadOptions={debouncedLoadProducts}
								onChange={(value: {
									id: string
									name: string
									barcode: string
								}) => {
									setSelectedProduct(value.id)
								}}
								defaultInputValue={productBarcode}
								defaultOptions={!!productBarcode}
								defaultMenuIsOpen={!!productBarcode}
							/>
						</div>
						<div className='col-2 center'>
							{!checkIfProductMatches() ? (
								<button
									style={scannerBtnStyle}
									className='btn btn-orange'
									type='button'
									onClick={() => setScannerType('product')}
								>
									<span className='fas fa-qrcode' />
								</button>
							) : (
								<i className='fas fa-check green'></i>
							)}
						</div>
					</div>
				</div>

				<div>
					<label className='label'>Quantity</label>
					<input
						value={quantity}
						max={item.quantity - item.placed_quantity}
						min={0}
						onChange={(e) => {
							setQuantity(
								isNaN(parseInt(e.target.value))
									? null
									: parseInt(e.target.value)
							)
						}}
						className='input input-sm'
						type='number'
					/>
				</div>

				<>
					<button
						disabled={!checkIfProductMatches()}
						className='btn btn-orange w-full flex mt-md'
					>
						<div className='col-11'>ADD TO CART</div>
						<div className='col-1'>
							<span className='fas fa-cart-plus' />
						</div>
					</button>
				</>
			</form>
			{scannerType && (
				<>
					<div className={`full modal`} style={{ zIndex: 100 }}>
						<div className='close' onClick={() => setScannerType(null)}>
							<i className='fas fa-times'></i>
						</div>
						<Scanner onDetect={onDetect} />
					</div>
				</>
			)}
		</Modal>
	)
}

const scannerBtnStyle = {
	display: 'flex',
	justifyContent: 'center',
	height: '2.5em',
	width: '2.5em',
}

export default AddItemModal
