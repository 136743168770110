import Modal from '../../layouts/Modal'
import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import Toolkit from '../../utils/Toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import PutawayApi from '../../apis/PutawayApi'
import { components } from 'react-select'
import Scanner from '../Scanner'

const NoOptionsMessage = (props) => {
	return (
		<components.NoOptionsMessage {...props}>
			<span>No Products Found</span>
		</components.NoOptionsMessage>
	)
}

const VerifyStorage = ({ complete, onSubmit }) => {
	// const [quantity, setQuantity] = useState<number>(item.quantity - item.placed_quantity);
	const [scannerType, setScannerType] = useState(null)
	const [productBarcode, setProductBarcode] = useState(undefined)

	// const NoOptionsMessage = (props) => {
	//   return (
	//     <components.NoOptionsMessage {...props}>
	//       <span>No Options Found</span>
	//     </components.NoOptionsMessage>
	//   );
	// };

	const rootState = useSelector((state: RootState) => state)
	const apiProps = Toolkit.mapStateToApiProps(rootState)

	const loadProducts = async (value) => {
		const val = value || productBarcode
		const response = await new PutawayApi(apiProps).searchProduct(val)
		const filteredProductlist = response.data.data
		return filteredProductlist
		//const response = await new PutawayApi(apiProps).searchProduct(val);
		//return response.data.data;
	}

	const onDetect = (barcode: { barcode: string }) => {
		if (scannerType == 'product') {
			setProductBarcode(barcode)
		}
		setScannerType(null)
	}

	const checkIfProductMatches = () => {
		// if (item.product.id === selectedProduct) {
		//   return true;
		// } else {
		//   return false;
		// }
		console.log('checkIfProductMatches Function Clicked')
		return false
	}

	const submit = (e) => {
		e.preventDefault()
		// onSubmit(quantity);
		onSubmit()
		complete()
	}

	return (
		<Modal>
			<h1 className='title mt-lg'>SOURCE LOCATION</h1>
			{/* <div>
        <h5>Product Name : {item.product.name}</h5>
        <h5>Quantity Assigned: {item.quantity - item.placed_quantity}</h5>
      </div> */}

			<form className='mt-lg' onSubmit={submit}>
				<div className='mt-md'>
					<label className='label'>Scan Product</label>
					<div className='grid gutter-md c-center'>
						<div className='col-10'>
							<AsyncSelect
								autoFocus
								key={productBarcode}
								components={{ NoOptionsMessage }}
								getOptionLabel={(e: {
									id: string
									name: string
									barcode: string
								}) => `${e.name}: ${e.barcode}`}
								getOptionValue={(e: { id: string }) => e.id}
								loadOptions={loadProducts}
								defaultInputValue={productBarcode}
								defaultOptions={!!productBarcode}
								defaultMenuIsOpen={!!productBarcode}
							/>
						</div>
						<div className='col-2 center'>
							{!checkIfProductMatches() ? (
								<button
									style={scannerBtnStyle}
									className='btn btn-orange'
									type='button'
									onClick={() => setScannerType('product')}
								>
									<span className='fas fa-qrcode' />
								</button>
							) : (
								<i className='fas fa-check green'></i>
							)}
						</div>
					</div>
				</div>

				<div>
					<label className='label'>Quantity</label>
					{/* <input
            value={quantity}
            max={item.quantity - item.placed_quantity}
            min={0}
            onChange={(e) => {
              setQuantity(
                isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value)
              );
            }}
            className="input input-sm"
            type="number"
          /> */}
				</div>

				<>
					<button
						disabled={!checkIfProductMatches()}
						className='btn btn-orange w-full flex mt-md'
					>
						<div className='col-11'>ADD TO CART</div>
						<div className='col-1'>
							<span className='fas fa-cart-plus' />
						</div>
					</button>
				</>
			</form>
			{scannerType && (
				<>
					<div className={`full modal`} style={{ zIndex: 100 }}>
						<div className='close' onClick={() => setScannerType(null)}>
							<i className='fas fa-times'></i>
						</div>
						<Scanner onDetect={onDetect} />
					</div>
				</>
			)}
		</Modal>
	)
}

const scannerBtnStyle = {
	display: 'flex',
	justifyContent: 'center',
	height: '2.5em',
	width: '2.5em',
}

export default VerifyStorage
