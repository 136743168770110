import Modal from '../../../layouts/Modal'
import { ModalProps } from '../../ModalManager'

interface Props extends ModalProps {
	action: (id: string | number) => void
	message: string
	content?: string
	contentHindi?: string
	id: number | string
	confirmButtonText?: string
	discardButtonText?: string
	inputType: string
	inputPlaceholder: string
}

export enum MessageType {
	ERROR = 'Error',
	WARNING = 'Warning',
	SUCCESS = 'Success',
	INFO = 'Info',
}

const NewAuditErrorModal = ({ message, content, contentHindi }: Props) => {
	return (
		<Modal smallModal={true}>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<img
					// src={
					// 	message === MessageType.ERROR
					// 		? '/images/error.svg'
					// 		: message === MessageType.WARNING
					// 		? '/images/alert.svg'
					// 		: message === MessageType.INFO
					// 		? '/images/info.svg'
					// 		: '/images/success1.svg'
					// }
					src={
						(message === MessageType.ERROR && '/images/error.svg') ||
						(message === MessageType.WARNING && '/images/alert.svg') ||
						(message === MessageType.INFO && '/images/info.svg') ||
						(message === MessageType.SUCCESS && '/images/success1.svg')
					}
					style={{ height: '80px', width: '80px' }}
					alt={message}
				/>
			</div>
			<h1 className='title center margin-bottom-10'>{message}</h1>
			{content && (
				<div className='md mt-md black margin-bottom-10 center'>{content}</div>
			)}
			{content && (
				<div className='md mt-md black margin-y-16 center'>{contentHindi}</div>
			)}
		</Modal>
	)
}

export default NewAuditErrorModal
