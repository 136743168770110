import Modal from '../../../layouts/Modal'
import { ModalProps } from '../../ModalManager'
import style from './iatConfirmModal.module.css'
import { useState } from 'react'

interface Props extends ModalProps {
	action: (quantity: number) => void
	message: string
	content?: string
	maxQuanityAllowed?: number
}

const IATQuantitySaveModal = ({
	complete,
	action,
	message,
	content,
	maxQuanityAllowed,
}: Props) => {
	const [quantity, setQuantity] = useState('')

	const success = () => {
		if (Number.isInteger(+quantity)) {
			action(+quantity)
			complete()
		} else {
			alert('Decimals not allowed!')
		}
	}

	return (
		<Modal smallModal={true}>
			<h1 className='title center'>{message}</h1>
			{content && <div className='md mt-md center black'>{content}</div>}
			<div className='mt-lg grid m-space-evenly'>
				<div className='col-12 center'>
					<input
						onKeyDown={(e) => {
							if (e.key === '.') {
								e.preventDefault()
							}
						}}
						id='quantity'
						type='number'
						placeholder='Enter Quantity'
						onChange={(event) => setQuantity(event.target.value)}
					/>
				</div>

				{+quantity > maxQuanityAllowed ? (
					<>
						<div className='pv-lg md bold red'>
							Quantity exceede the max quanity allowed
						</div>

						<div className='col-4 mt-md'>
							<button className={`btn w-full h-full disabled`}>Proceed</button>
						</div>
					</>
				) : (
					<div className='col-4 mt-md'>
						<button
							className={`btn w-full h-full ${style.no}`}
							onClick={success}
						>
							Proceed
						</button>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default IATQuantitySaveModal
