import { RootState } from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import AppActions from '../redux/actions/AppAction'
import ConfirmModal from './modals/ConfirmModal'
import ScanModal from './modals/ScanModal'
import AddItemModal from './modals/putaway/AddItemModal'
import AssignStorageModal from './modals/putaway/AssignStorageModal'
import TransferModal from './modals/transfer/TransferModal'
import MigrationModal from './modals/migration/MigrationModal'
import BadStockModal from './modals/audit/BadStockModal'
import IncorrectProductInfoModal from './modals/audit/IncorrectProductInfoModal'
import AuditScanModal from './modals/audit/AuditScanModal'
import ExtraItemModal from './modals/audit/ExtraItemModal'
import IATConfirmModal from './modals/iat/IATConfirmModal'
import IATScanModal from './modals/iat/IATScanModal'
import IATQuantitySaveModal from './modals/iat/IATQuantitySaveModal'
import VerifyStorage from './modals/VerifyStorage'
import LocationScanModal from './modals/dispatch/LocationScanModal'
import NewConfirmModal from './modals/NewConfirmModal'
import NewConfirmModalWithInput from './modals/newAudit/NewConfirmModalWithInput'
import NewAuditScanModal from './modals/newAudit/NewAuditScanModal'
import NewAuditErrorModal from './modals/newAudit/NewAuditErrorModal'
import NewAuditProductSearchModal from './modals/newAudit/NewAuditProductSearchModal'

export enum ModalName {
	CONFIRM = 'confirm',
	SCAN = 'scan',
	ADD_PRODUCT = 'add-product',
	ASSIGN_STORAGE = 'assign-storage',
	TRANSFER = 'transfer',
	MIGRATION = 'migration',
	BAD_STOCK = 'bad-stock',
	INCORRECT_PRODUCT_INFO = 'incorrect_prodcut_info',
	AUDIT = 'audit',
	EXTRA_ITEM_MODAL = 'extra-item',
	IAT_CONFIRM = 'iat-confirm',
	IAT_SCAN = 'iat-scan',
	IAT_QUANTITY_SAVE = 'iat-quantity-save',
	VERIFY_STORAGE = 'verify-storage',
	DISPATCH_LOCATION_SCAN = 'dispatch_location_scan',
	NEW_CONFIRM_MODAL = 'new_confirm_modal',
	NEW_CONFIRM_MODAL_WITH_INPUT = 'new_confirm_modal_with_input',
	NEW_AUDIT_SCAN_MODAL = 'new_audit_scan_modal',
	NEW_AUDIT_ERROR_MODAL = 'new_audit_error_modal',
	NEW_AUDIT_PRODUCT_SEARCH_MODAL = 'new_audit_product_search_modal',
}

export interface ModalProps {
	complete: () => void
}

const mapRootState = (rootState: RootState) => {
	if (rootState.appState.modal) {
		return {
			name: rootState.appState.modal.name,
			data: rootState.appState.modal.data,
		}
	}
	return { name: null, data: null }
}

const ModalManager = () => {
	const dispatch = useDispatch()
	const { name, data } = useSelector(mapRootState)

	const complete = () => {
		dispatch(AppActions.switchModal(null))
	}

	let Modal = null
	switch (name) {
		case ModalName.CONFIRM:
			Modal = ConfirmModal
			break

		case ModalName.SCAN:
			Modal = ScanModal
			break

		case ModalName.VERIFY_STORAGE:
			Modal = VerifyStorage
			break

		case ModalName.ADD_PRODUCT:
			Modal = AddItemModal
			break

		case ModalName.ASSIGN_STORAGE:
			Modal = AssignStorageModal
			break

		case ModalName.TRANSFER:
			Modal = TransferModal
			break

		case ModalName.MIGRATION:
			Modal = MigrationModal
			break

		case ModalName.BAD_STOCK:
			Modal = BadStockModal
			break

		case ModalName.INCORRECT_PRODUCT_INFO:
			Modal = IncorrectProductInfoModal
			break
		case ModalName.MIGRATION:
			Modal = MigrationModal
			break
		case ModalName.AUDIT:
			Modal = AuditScanModal
			break
		case ModalName.EXTRA_ITEM_MODAL:
			Modal = ExtraItemModal
			break
		case ModalName.IAT_CONFIRM:
			Modal = IATConfirmModal
			break
		case ModalName.IAT_SCAN:
			Modal = IATScanModal
			break
		case ModalName.IAT_QUANTITY_SAVE:
			Modal = IATQuantitySaveModal
			break
		case ModalName.DISPATCH_LOCATION_SCAN:
			Modal = LocationScanModal
			break

		case ModalName.NEW_CONFIRM_MODAL:
			Modal = NewConfirmModal
			break

		case ModalName.NEW_CONFIRM_MODAL_WITH_INPUT:
			Modal = NewConfirmModalWithInput
			break

		case ModalName.NEW_AUDIT_SCAN_MODAL:
			Modal = NewAuditScanModal
			break

		case ModalName.NEW_AUDIT_ERROR_MODAL:
			Modal = NewAuditErrorModal
			break

		case ModalName.NEW_AUDIT_PRODUCT_SEARCH_MODAL:
			Modal = NewAuditProductSearchModal
			break
	}
	if (Modal) {
		return <Modal {...data} complete={complete} />
	}

	return null
}

export default ModalManager
