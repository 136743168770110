import Product from './Product'
import * as yup from 'yup'
import Storage from './Storage'

export interface PutawayItem {
	id: number
	product: Product
	quantity: number
	storageId?: number
	sku: string
	storage: Storage
}

export interface addCartItemType {
	product_id: string
	variant: string
	quantity: number
	migration_item_id: number
	sku: string
}

export const ItemSchema = yup.object().shape({
	product: yup.number().required(),
	sku: yup.object().required(),
	quantity: yup.string().required(),
})

export type PutawayType = 'fresh' | 'return' | 'transformation'
