import React, { useEffect, useState } from 'react'
import Select, { StylesConfig } from 'react-select'
import Modal from '../../../layouts/Modal'
import AuditAction from '../../../redux/actions/AuditAction'
import { useThunkyDispatch } from '../../../redux/store'
import CustomPrimaryButton from '../../buttons/CustomPrimaryButton'
import { ModalProps } from '../../ModalManager'

type OptionType = {
	value: string
	label: string
}

const customStyles: StylesConfig<OptionType, false> = {
	control: (base) => ({
		...base,
		height: 43,
		minHeight: 43,
	}),
}

interface Props extends ModalProps {
	action: (reasonList: { reasonId: number; quantity: number }[]) => void
	message: string
}

const BadStockModal = ({ complete, action, message }: Props) => {
	const [quantity, setQuantity] = useState([])
	const [reasonSelected, setReasonSelected] = useState([])
	const [ids, setIds] = useState([0])
	const [reasonList, setReasonsList] = useState(null)
	const dispatch = useThunkyDispatch()

	useEffect(() => {
		getReasons()
	}, [])
	const getReasons = async () => {
		const result = await dispatch(AuditAction.getReasons())
		//setReasonsList(result);
		populateReasonList(result)
	}

	const populateReasonList = (result) => {
		const data = []
		result.forEach((item) => {
			const reason = { value: item.id, label: item.text }
			data.push(reason)
		})
		setReasonsList(data)
	}

	const success = () => {
		const reasonList = []
		ids.forEach((id) => {
			if (quantity[id] > 0) {
				reasonList.push({
					reasonId: reasonSelected[id].value,
					quantity: quantity[id],
				})
			}
		})

		action(reasonList)
		complete()
	}

	const handleQuantityChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		id: number
	) => {
		const value = event.target.value
		const tempQuantity = [...quantity]
		tempQuantity[id] = isNaN(parseInt(value)) ? 0 : parseInt(value)
		setQuantity(tempQuantity)
	}

	const handleReasonsChange = (val, id: number) => {
		const tempReason = [...reasonSelected]
		tempReason[id] = val
		setReasonSelected(tempReason)
	}

	const handleAddAnotherReason = () => {
		const length = ids.length
		const tempIds = [...ids, length]
		setIds(tempIds)
		setQuantity((prev) => [...prev, ''])
		setReasonSelected((prev) => [...prev, ''])
	}

	return (
		<Modal smallModal={true}>
			<h1 className='title mt-lg'>{message}</h1>
			{ids.map((id) => (
				<BadStockForm
					key={id}
					id={id}
					quantity={quantity}
					handleQuantityChange={handleQuantityChange}
					reasonSelected={reasonSelected}
					handleReasonsChange={handleReasonsChange}
					reasonList={reasonList}
				/>
			))}
			<div className='mt-md grid gutter-around'>
				<div className='col-8'>
					<CustomPrimaryButton
						label='Add Another Reason'
						handleClick={handleAddAnotherReason}
						isDisabled={
							quantity[quantity.length - 1] === '' ||
							reasonSelected[reasonSelected?.length - 1] === ''
								? true
								: false
						}
						secondary={true}
					/>
				</div>
				<div className='col-3'>
					<CustomPrimaryButton
						label='Done'
						handleClick={success}
						isDisabled={
							quantity[quantity?.length - 1] === '' ||
							reasonSelected[reasonSelected?.length - 1] === ''
								? true
								: false
						}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default BadStockModal

const BadStockForm = ({
	id,
	quantity,
	handleQuantityChange,
	reasonSelected,
	handleReasonsChange,
	reasonList,
}) => {
	return (
		<div className='mt-md grid gutter-around'>
			<div className='col-3'>
				<input
					className='input input-sm'
					type='number'
					min={0}
					value={quantity[id] == undefined ? 0 : quantity[id]}
					onChange={(event) => handleQuantityChange(event, id)}
					placeholder='Quantity'
				/>
			</div>
			<div className='col-8'>
				<Select
					options={reasonList}
					value={reasonSelected[id]}
					onChange={(val) => handleReasonsChange(val, id)}
					placeholder='Reason'
					styles={customStyles}
				/>
			</div>
		</div>
	)
}
