import React from 'react'

function Image({
	src = '/images/logo.png',
	alt,
	fallback = '/images/logo.png',
}) {
	return (
		<img
			src={src}
			alt={alt}
			onError={({ currentTarget }) => {
				currentTarget.onerror = null // prevents looping
				currentTarget.src = fallback
			}}
		></img>
	)
}

export default Image
