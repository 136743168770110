import { useState } from 'react'
import router from 'next/router'

import Modal from '../../../layouts/Modal'
import { ModalName } from '../../ModalManager'
import IndividualCharInputBox from '../../IndividualCharInputBox/IndividualCharInputBox'
import ModalHeader from '../../ModalHeader'
import { BottomSheetWrapper } from '../../styleComponents/BottomSheetWrapper'
import Quagga from 'quagga'
import Scanner from '../../Scanner'
import AppAction from '../../../redux/actions/AppAction'
import { RootState, useThunkyDispatch } from '../../../redux/store'
import { useSelector } from 'react-redux'

const AuditScanModal = ({
	complete,
	addProdcutToStore,
	item,
	storagelabel,
	productModal = false,
}) => {
	const deviceType = useSelector(
		(state: RootState) => state.appState.deviceType
	)
	const [showManualInputBox, setshowManualInputBox] = useState(
		deviceType == 'mobile' ? false : true
	)
	const [newStorageID, setNewStorageID] = useState(null)

	const dispatch = useThunkyDispatch()

	const onDetect = (barcode: { barcode: string }) => {
		if (!productModal) {
			verifyStorage(barcode)
		} else {
			if (barcode === item.product.barcode) {
				addProdcutToStore(item)

				try {
					Quagga.stop()
				} catch (error) {
					console.log(error)
				}
				router.push('/audit/' + item.auditId + '/items/' + item.id)
				complete()
			}
		}
	}

	const verifyStorage = async (data) => {
		if (storagelabel != null) {
			if (storagelabel === data) {
				await complete()
				dispatch(
					AppAction.switchModal(ModalName.AUDIT, {
						addProdcutToStore: addProdcutToStore,
						complete: complete,
						item: item,
						storagelabel: storagelabel,
						productModal: true,
					})
				)
				dispatch(
					AppAction.displayMessage(
						'Location verified , please scan the product  ',
						false,
						2000
					)
				)
			} else {
				dispatch(AppAction.displayMessage('Incorrect storage ', true, 2000))
			}
		} else {
			const tmp_item = item
			tmp_item.new_storage_label = newStorageID
			await complete()
			dispatch(
				AppAction.switchModal(ModalName.AUDIT, {
					addProdcutToStore: addProdcutToStore,
					complete: complete,
					item: item,
					storagelabel: storagelabel,
					productModal: true,
				})
			)
			dispatch(
				AppAction.displayMessage(
					'Location assigned , please scan the product  ',
					false,
					2000
				)
			)
		}
	}
	const manualInput = () => {
		setshowManualInputBox(true)
	}

	const handleBarCodeDigitsInput = (data: string) => {
		if (data != '' && data.length == 5) {
			if (item.product.barcode.slice(-5) == data) {
				// go to prodcut detail page
				addProdcutToStore(item)

				try {
					Quagga.stop()
				} catch (error) {
					console.log(error)
				}
				router.push('/audit/' + item.auditId + '/items/' + item.id)
				complete()
			}
		}
	}

	const handleStorageChange = async (event) => {
		if (storagelabel != null) {
			const qt = event.target.value
			if (qt != null && qt != '') {
				if (qt === storagelabel) {
					await complete()
					dispatch(
						AppAction.switchModal(ModalName.AUDIT, {
							complete: complete,
							item: item,
							addProdcutToStore: addProdcutToStore,
							storagelabel: storagelabel,
							productModal: true,
							message: 'Extra Items',
						})
					)
					setshowManualInputBox(false)

					dispatch(
						AppAction.displayMessage(
							'Location verified , please scan the product  ',
							false,
							2000
						)
					)
				}
			}
		} else {
			const tmp_item = item
			tmp_item.new_storage_label = newStorageID
			await complete()
			dispatch(
				AppAction.switchModal(ModalName.AUDIT, {
					complete: complete,
					item: item,
					addProdcutToStore: addProdcutToStore,
					storagelabel: storagelabel,
					productModal: true,
					message: 'Extra Items',
				})
			)
			setshowManualInputBox(false)

			dispatch(
				AppAction.displayMessage(
					'Location assigned , please scan the product  ',
					false,
					2000
				)
			)
		}
	}

	return (
		<Modal fullScreen={true}>
			{!showManualInputBox && <Scanner onDetect={onDetect} />}
			<ModalHeader title={productModal ? 'Scan Product' : 'Scan Storage'} />

			{productModal && (
				<BottomSheetWrapper>
					<i
						onClick={() => setshowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div onClick={manualInput} className='sm bold black underline'>
						Cant Scan? Try to input numbers
					</div>
					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>
								Enter the last 5 digit of the barcode
							</div>
							<IndividualCharInputBox
								amount={5}
								autoFocus
								handleOutputString={handleBarCodeDigitsInput}
								inputRegExp={/^[0-9]$/}
								type='number'
							/>
						</>
					)}
				</BottomSheetWrapper>
			)}

			{!productModal && (
				<BottomSheetWrapper>
					<i
						onClick={() => setshowManualInputBox(false)}
						className='fas fa-camera'
						style={{ float: 'right', fontSize: 20 }}
					></i>
					<div className='sm bold black underline' onClick={manualInput}>
						Cant Scan? Try to input Storage Label
					</div>

					{showManualInputBox && (
						<>
							<div className='pv-lg md bold'>Enter the Storage Label</div>

							<input
								id='name'
								autoFocus
								type='text'
								placeholder={storagelabel}
								onChange={(event) => {
									if (storagelabel == null) {
										setNewStorageID(event.target.value)
									} else {
										handleStorageChange(event)
									}
								}}
							/>

							{storagelabel == null && (
								<button
									className='btn btn-sm btn-green mt-lg w-full'
									onClick={(event) => handleStorageChange(event)}
								>
									Assign Storage
								</button>
							)}
						</>
					)}
				</BottomSheetWrapper>
			)}
		</Modal>
	)
}

export default AuditScanModal
