import Product from './Product'
import Storage from './Storage'
import * as yup from 'yup'

export enum TransferItemStatus {
	PENDING = 'pending',
	PROCESSING = 'processing',
	COMPLETED = 'completed',
}

export interface TransferItem {
	id: number
	product: Product
	quantity: number
	source_storage: Storage
	destination_storage: Storage
	status: TransferItemStatus
}

export const TransferItemSchema = yup.object().shape({
	product: yup.number().required(),
	source_location: yup.string().required(),
	quantity: yup.string().required(),
	remarks: yup.string(),
})
