import React from 'react'

interface Props {
	amount: number
	autoFocus: boolean
	handleOutputString: (data: string) => void
	inputRegExp: RegExp
	password?: boolean
	type: string
}

const IndividualCharInputBox = React.memo(function IndividualCharInputBox(
	props: Props
) {
	// const [characterArray, setCharacterArray] = useState(
	// 	Array(props.amount).fill(null)
	// )

	// const [inputSize] = useState(Array(props.amount).fill(''))

	//setting the focus to first element
	// useEffect(() => {
	//     if (props.autoFocus) {
	//         const input: HTMLElement = document.querySelector("#input0");
	//         input.focus();
	//     }
	// }, []);

	// const handleChange = ({ target }) => {
	// 	if (target.value.match(props.inputRegExp)) {
	// 		focusNextChar(target)
	// 		setModuleOutput(target)
	// 	} else {
	// 		target.value = characterArray[target.name.replace('input', '')]
	// 	}
	// }

	// const handleKeyDown = ({ target, key }) => {
	// 	if (key === 'Backspace') {
	// 		if (target.value === '' && target.previousElementSibling !== null) {
	// 			target.previousElementSibling.value = ''
	// 			focusPrevChar(target)
	// 		} else {
	// 			target.value = ''
	// 		}
	// 		setModuleOutput(target)
	// 	} else if (key === 'ArrowLeft') {
	// 		focusPrevChar(target)
	// 	} else if (key === 'ArrowRight' || key === ' ') {
	// 		target
	// 	}
	// }

	// const handleFocus = ({ target }) => {
	// 	const el = target
	// 	// In most browsers .select() does not work without the added timeout.
	// 	setTimeout(function () {
	// 		el.select()
	// 	}, 0)
	// }

	// const focusPrevChar = (target) => {
	// 	if (target.previousElementSibling !== null) {
	// 		target.previousElementSibling.focus()
	// 	}
	// }

	// const focusNextChar = (target) => {
	// 	if (target.nextElementSibling !== null) {
	// 		target.nextElementSibling.focus()
	// 	}
	// }

	// const setModuleOutput = (target) => {
	// 	const { name, value } = target

	// 	const tempCharacters = [...characterArray]
	// 	//size of text input and after that will get the index value
	// 	let index = name.slice('5')
	// 	try {
	// 		index = parseInt(index)
	// 	} catch (error) {
	// 		console.log(error)
	// 	}

	// 	tempCharacters[index] = value
	// 	setCharacterArray(tempCharacters)

	// 	props.handleOutputString(tempCharacters.join(''))
	// }

	const inputChange = ({ target }) => {
		const value = target.value
		if (value.length >= 5) {
			if (value.length === 5) {
				props.handleOutputString(value)
			} else {
				props.handleOutputString(value.slice(-5))
			}
		}
	}

	return (
		<div>
			{/* {inputSize.map((_, index) => (
                <InputBox
                    type={props.type}
                    key={index}
                    handleKeyDown={handleKeyDown}
                    handleFocus={handleFocus}
                    handleChange={handleChange}
                    name={"input" + index}
                    id={"input" + index}
                />
            ))} */}
			<input autoFocus onChange={inputChange} type='number' />
		</div>
	)
})

export default IndividualCharInputBox
