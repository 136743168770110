import { useSelector } from 'react-redux'
import { RootState, useThunkyDispatch } from '../redux/store'
import { useRouter } from 'next/router'
import { useState } from 'react'
import AppAction from '../redux/actions/AppAction'

const mapRootState = (rootState: RootState) => {
	return {
		warehouseName: rootState.appState.warehouse,
		activeWarehouseIndex: rootState.appState.activeWarehouseIndex,
	}
}

const Home = ({ openSidebar }) => {
	console.log({ openSidebar })
	const { warehouseName, activeWarehouseIndex } = useSelector(mapRootState)
	const [selectedwarehouse, setSelectedWarehouse] =
		useState(activeWarehouseIndex)
	const [displayWareHouseList, setDisplayWareHouseList] = useState(false)
	const dispatch = useThunkyDispatch()
	//  const setWarehouse

	const logout = () => {
		dispatch(AppAction.logout())
	}

	const changeStore = () => {
		setDisplayWareHouseList(!displayWareHouseList)
		//alert('change')
	}

	const changeWarehouse = (index) => {
		setSelectedWarehouse(index)
		dispatch(AppAction.switchStore(index))
	}

	const displayStores = () => {
		return (
			<div
				style={{
					zIndex: 10,
					backgroundColor: '#262d33',
					position: 'absolute',
					top: 10,
					right: 0,
				}}
				className='col-3 right'
			>
				{warehouseName &&
					warehouseName.map((value, index) => {
						let store = null
						// let hidden = "";
						// if (selectedwarehouse != index) {
						//     // if (!displayWareHouseList) {
						//     //     hidden = "hidden";
						//     }
						// }
						store = (
							<ul
								onClick={() => changeWarehouse(index)}
								style={{ margin: 10, borderWidth: 1, cursor: 'pointer' }}
								className={`storename mr-xs`}
								key={`${value?.name}+${index}`}
							>
								{warehouseName[index].name}
							</ul>
						)

						return store
					})}
			</div>
		)
	}

	return (
		<>
			<div className='col-3' onClick={() => logout()}>
				<i className='fas fa-power-off'></i>
			</div>
			<div className='col-6 center'>
				<div className='semi-bold'>MERA 1K</div>
			</div>

			<div className='col-3 right' onClick={changeStore}>
				<div className='xs normal ellipsis'>
					<span>
						<i className='fas fa-map-marker-alt'></i>
					</span>{' '}
					<span className='mr-xs'>
						{warehouseName[selectedwarehouse] &&
							warehouseName[selectedwarehouse].name}
					</span>
				</div>
				{displayWareHouseList && displayStores()}
			</div>
		</>
	)
}

const PageTitle = ({ title, backUrl }) => {
	const router = useRouter()
	return (
		<>
			<div className='col-3'>
				<span
					onClick={() => (backUrl ? router.replace(backUrl) : router.back())}
				>
					<i className='fas fa-angle-left'></i>
				</span>
			</div>
			<div className='col-6 center'>
				<div className='semi-bold'>{title}</div>
			</div>
			<div className='col-3 right'></div>
		</>
	)
}

const NewPageTitle = ({ title, backUrl }) => {
	const router = useRouter()
	return (
		<>
			<div className='flex'>
				<span
					onClick={() => (backUrl ? router.replace(backUrl) : router.back())}
				>
					<i className='fas fa-angle-left'></i>
				</span>
				<div className='semi-bold margin-left-16'>{title}</div>
			</div>
		</>
	)
}

const Header = ({ title, openSidebar, backUrl }) => {
	return (
		<header className='header'>
			<div className='grid gutter-between c-center white p-md xs'>
				{title ? (
					<PageTitle title={title} backUrl={backUrl} />
				) : (
					<Home openSidebar={openSidebar} />
				)}
			</div>
		</header>
	)
}

export const NewHeader = ({ title, openSidebar, backUrl }) => {
	return (
		<header className='new-header' style={{ backgroundColor: '#f9fafe' }}>
			<div className='grid gutter-between c-center p-md xs'>
				{title ? (
					<NewPageTitle title={title} backUrl={backUrl} />
				) : (
					<Home openSidebar={openSidebar} />
				)}
			</div>
		</header>
	)
}

export default Header
