import styles from './button.module.css'

export interface ButtonProps {
	text: string
	handleClick: (id?: string | number) => void
	id?: string | number
	buttonIconUrl?: string
	[key: string]: unknown
	full?: boolean
}

export const WarningOutlineButton = ({
	text,
	handleClick,
	id,
	buttonIconUrl,
	...restProps
}: ButtonProps) => {
	return (
		<button
			onClick={() => handleClick(id)}
			className={`${styles.btn} ${styles.dangerOutline}`}
			{...restProps}
		>
			{text}
			{buttonIconUrl && <img src={buttonIconUrl} alt='warning' />}
		</button>
	)
}

export const PrimaryButton = ({
	text,
	handleClick,
	id,
	buttonIconUrl,
	full,
	...restProps
}: ButtonProps) => {
	return (
		<button
			onClick={() => handleClick(id)}
			className={`${styles.btn} ${styles.primary} ${full && styles.full}`}
			{...restProps}
		>
			{text}
			{buttonIconUrl && <img src={buttonIconUrl} alt='scan or enter' />}
		</button>
	)
}

export const PrimaryOutlineButton = ({
	text,
	handleClick,
	id,
	buttonIconUrl,
	...restProps
}: ButtonProps) => {
	return (
		<button
			onClick={() => handleClick(id)}
			className={`${styles.btn} ${styles.primaryOutline}`}
			{...restProps}
		>
			{text}
			{buttonIconUrl && <img src='/images/scanNew.svg' alt='scan or enter' />}
		</button>
	)
}
export const SecondaryButton = ({
	text,
	handleClick,
	id,
	...restProps
}: ButtonProps) => {
	return (
		<button
			onClick={() => handleClick(id)}
			className={`${styles.btn} ${styles.outline}`}
			{...restProps}
		>
			{text}
		</button>
	)
}
