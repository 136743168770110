import styled from 'styled-components'

export const BottomSheetWrapper = styled.div`
	bottom: 0;
	position: absolute;
	width: 100%;
	text-align: center;
	background-color: #f5f5f5;
	border-color: black;
	border-radius: 2rem 2rem 0 0;
	padding: 1.25rem;
`
